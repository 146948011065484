import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { useAuth } from '../../contexts/AuthContext.js';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { cancelSubscription, getBillingHistory, addSeat, removeUnusedSeats, getSeatStatus } from '../../services/ApiService';
// import SeatManagementModal from '../../components/Subscription/SeatManagementModal'; // Adjust path if needed
import UserManagementPanel from '../../components/Subscription/UserManagementPanel';

function ProfileDetail() {
    const { currentUser } = useAuth();
    const { openSubscriptionModal } = useSubscription();
    const [loading, setLoading] = useState(false);
    const [billingHistory, setBillingHistory] = useState([]);
    const [showSeatModal, setShowSeatModal] = useState(false);

    const [seatStatus, setSeatStatus] = useState({
        totalSeats: 0,
        usedSeats: 0,
        remainingSeats: 0
    });

    const fetchSeatStatus = async () => {
        try {
            const response = await getSeatStatus();
            // const data = await response.json();

            setSeatStatus(response);
        } catch (error) {
            console.error('Error fetching seat status:', error);
        }
    };

    useEffect(() => {
        if (currentUser) {
            fetchSeatStatus();
        }
    }, [currentUser]);

    let userSinceFormattedDate = '';

    if (currentUser?.createdAt) {
        const date = typeof currentUser.createdAt.toDate === 'function'
            ? currentUser.createdAt.toDate()
            : currentUser.createdAt;

        userSinceFormattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    useEffect(() => {
        const fetchBillingHistory = async () => {
            try {
                const history = await getBillingHistory();
                setBillingHistory(history);
            } catch (err) {
                console.error('Failed to load billing history', err);
            }
        };

        fetchBillingHistory();
    }, []);

    const handleCancel = async () => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Owwnn...",
            text: "Are you sure you want to cancel your subscription?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, cancel it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true);
                    const res = await cancelSubscription();
                    Swal.fire({
                        title: "Canceled!",
                        text: res.message || "Your subscription has been canceled.",
                        icon: "success",
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: "btn btn-success"
                        },
                        buttonsStyling: false
                    }).then(() => {
                        window.location.reload(); // Or trigger refetch of user data
                    });
                } catch (err) {
                    Swal.fire({
                        title: "Error",
                        text: "Error canceling subscription",
                        icon: "error",
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: "btn btn-danger"
                        },
                        buttonsStyling: false
                    });
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    const handleAddSeat = async () => {
        const pricePerSeat = currentUser?.SubscriptionPriceAmount || 0;
        const currency = currentUser?.SubscriptionPriceCurrency || 'USD';

        const confirmation = await Swal.fire({
            title: 'Add New Seat?',
            text: `A new seat will cost ${currency} ${pricePerSeat.toFixed(2)} more per month. Do you want to continue?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, add seat',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light'
            },
            buttonsStyling: false
        });

        if (confirmation.isConfirmed) {
            try {
                const data = await addSeat();

                const result = await Swal.fire({
                    title: 'Success!',
                    text: `Seat added.`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: { confirmButton: 'btn btn-success' },
                    buttonsStyling: false
                });

                if (result.isConfirmed) {
                    window.location.reload();
                }
            } catch (error) {
                Swal.fire('Oops!', error.response?.data?.message || 'Could not add seat.', 'error');
            }
        }
    };

    const handleRemoveUnusedSeats = async () => {
        try {
            const data = await removeUnusedSeats();
            const result = await Swal.fire({
                title: 'Success!',
                text: `Seats removed.`,
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: { confirmButton: 'btn btn-success' },
                buttonsStyling: false
            });

            if (result.isConfirmed) {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire('Oops!', error.response?.data?.message || 'Could not remove seat.', 'error');
        }
    };

    return (
        <>
            <Loading isLoading={loading} />
            <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
                <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                        <Header />
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                            <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                    <div className="d-flex flex-stack flex-row-fluid">
                                        <div className="d-flex flex-column flex-row-fluid">
                                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                                <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                    <Link to={`/`}>
                                                        <span className="text-white text-hover-primary">
                                                            <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                                </li>
                                                <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">My Profile</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--begin::Wrapper container--> */}
                            <div className="app-container container-xxl d-flex">
                                {/* <!--begin::Main--> */}
                                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                    {/* <!--begin::Content wrapper--> */}
                                    <div className="d-flex flex-column flex-column-fluid">
                                        {/* <!--begin::Content--> */}
                                        <div id="kt_app_content" className="app-content">
                                            {/* <!--begin::Navbar--> */}
                                            <div className="card mb-5 mb-xl-10">
                                                <div className="card-body pt-9 pb-0">
                                                    {/* <!--begin::Details--> */}
                                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                                        {/* <!--begin: Pic--> */}
                                                        <div className="me-7 mb-4">
                                                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                                <img src={currentUser?.Photo} alt="image" />
                                                                {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div> */}
                                                            </div>
                                                        </div>
                                                        {/* <!--end::Pic--> */}
                                                        {/* <!--begin::Info--> */}
                                                        <div className="flex-grow-1">
                                                            {/* <!--begin::Title--> */}
                                                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                                {/* <!--begin::User--> */}
                                                                <div className="d-flex flex-column">
                                                                    {/* <!--begin::Name--> */}
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{currentUser?.Name}</a>
                                                                        {/* <a href="#">
                                                                            <i className="ki-outline ki-verify fs-1 text-primary"></i>
                                                                        </a> */}
                                                                    </div>
                                                                    {/* <!--end::Name--> */}
                                                                    {/* <!--begin::Info--> */}
                                                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                        <a href="#" className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                                                            {currentUser?.Email}</a>
                                                                    </div>

                                                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                        <div className="fw-bold d-flex align-items-center fs-5">
                                                                            {
                                                                                (() => {
                                                                                    const planVariants = {
                                                                                        FREE: { className: 'badge-light-success' },
                                                                                        SELF: { className: 'badge-light-info' },
                                                                                        EXPERT: { className: 'badge-light-danger' }
                                                                                    };

                                                                                    const variant = planVariants[currentUser?.Plan];
                                                                                    const planName = currentUser?.PlanName;

                                                                                    return variant && planName ? (
                                                                                        <span className={`badge ${variant.className} fw-bold fs-5 px-2 py-1`}>
                                                                                            {planName}
                                                                                        </span>
                                                                                    ) : null;
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {/* <!--end::Info--> */}
                                                                </div>
                                                                {/* <!--end::User--> */}
                                                            </div>
                                                            {/* <!--end::Title--> */}
                                                            {/* <!--begin::Stats--> */}
                                                            <div className="d-flex flex-wrap flex-stack">
                                                                {/* <!--begin::Wrapper--> */}
                                                                <div className="d-flex flex-column flex-grow-1 pe-8">
                                                                </div>
                                                                {/* <!--end::Wrapper--> */}
                                                            </div>
                                                            {/* <!--end::Stats--> */}
                                                        </div>
                                                        {/* <!--end::Info--> */}
                                                    </div>
                                                    {/* <!--end::Details--> */}
                                                    {/* <!--begin::Navs--> */}
                                                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                                        {/* <!--begin::Nav item--> */}
                                                        <li className="nav-item mt-2">
                                                            <a className="nav-link text-active-primary ms-0 me-10 py-5 active" href="#">Overview</a>
                                                        </li>
                                                        {/* <!--end::Nav item--> */}
                                                    </ul>
                                                    {/* <!--begin::Navs--> */}
                                                </div>
                                            </div>
                                            {/* <!--end::Navbar--> */}

                                            {!currentUser?.ExternalPaid && (
                                                <>
                                                    {/* <!--begin::Billing Summary--> */}
                                                    <div className="card mb-5 mb-xl-10">
                                                        {/* <!--begin::Card body--> */}
                                                        <div className="card-body">
                                                            {/* <!--begin::Notice--> */}
                                                            {/* <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6">
                                                        <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-semibold">
                                                                <h4 className="text-gray-900 fw-bold">We need your attention!</h4>
                                                                <div className="fs-6 text-gray-700">Your payment was declined. To start using tools, please
                                                                    <a href="#" className="fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Add Payment Method</a>.</div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                            {/* <!--end::Notice--> */}
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-lg-5">
                                                                    {/* <!--begin::Heading--> */}
                                                                    <h3 className="mb-2">Active since {userSinceFormattedDate}</h3>
                                                                    {currentUser?.SubscriptionEndDate && (() => {
                                                                        let endDate;

                                                                        if (currentUser.SubscriptionEndDate._seconds) {
                                                                            // Firestore Timestamp object
                                                                            endDate = new Date(currentUser.SubscriptionEndDate._seconds * 1000);
                                                                        } else {
                                                                            // Might be ISO string or Date object already
                                                                            endDate = new Date(currentUser.SubscriptionEndDate);
                                                                        }

                                                                        if (!isNaN(endDate.getTime())) {
                                                                            return (
                                                                                <p className="fs-6 text-gray-600 fw-semibold mb-0 mb-lg-15">
                                                                                    Your subscription is canceled and will remain active until {endDate.toLocaleDateString()}.
                                                                                </p>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })()}

                                                                    <p className="fs-6 text-gray-600 fw-semibold mb-0 mb-lg-15">
                                                                        We will send you a notification upon Subscription expiration.
                                                                    </p>

                                                                    {/* <!--end::Heading--> */}
                                                                    {/* <!--begin::Info--> */}
                                                                    {currentUser?.SubscriptionPriceAmount && currentUser?.Seats && (
                                                                        <div className="fs-5 mb-2">
                                                                            <span className="text-gray-800 fw-bold me-1">
                                                                                {currentUser.SubscriptionPriceCurrency === 'USD' ? '$' : 'R$'}
                                                                                {(currentUser.SubscriptionPriceAmount * currentUser.Seats).toFixed(2)}
                                                                            </span>
                                                                            <span className="text-gray-600 fw-semibold">
                                                                                Per Month ({currentUser.SubscriptionPriceCurrency === 'USD' ? '$' : 'R$'}{currentUser.SubscriptionPriceAmount} x {currentUser.Seats} seat{currentUser.Seats > 1 ? 's' : ''})
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    {/* <!--end::Info--> */}
                                                                    {/* <!--begin::Notice--> */}
                                                                    <div className="fs-6 text-gray-600 fw-semibold">Subscribed to {currentUser?.PlanName} plan.</div>
                                                                    {/* <!--end::Notice--> */}
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-lg-7">
                                                                    {/* <!--begin::Heading--> */}
                                                                    <div className="d-flex text-muted fw-bold fs-5 mb-3">
                                                                        <span className="flex-grow-1 text-gray-800">Team Members</span>
                                                                        <span className="text-gray-800">{seatStatus.usedSeats} of {seatStatus.totalSeats} Used</span>
                                                                    </div>
                                                                    {/* <!--end::Heading--> */}
                                                                    {/* <!--begin::Progress--> */}
                                                                    <div className="progress h-8px bg-light-primary mb-2">
                                                                        <div
                                                                            className="progress-bar bg-primary"
                                                                            role="progressbar"
                                                                            style={{ width: `${(seatStatus.usedSeats / seatStatus.totalSeats) * 100}%` }}
                                                                            aria-valuenow={seatStatus.usedSeats}
                                                                            aria-valuemin="0"
                                                                            aria-valuemax={seatStatus.totalSeats}
                                                                        ></div>
                                                                    </div>
                                                                    {/* <!--end::Progress--> */}
                                                                    {/* <!--begin::Description--> */}
                                                                    <div className="fs-6 text-gray-600 fw-semibold mb-10">{seatStatus.remainingSeats} User(s) remaining until your plan requires update</div>
                                                                    {/* <!--end::Description--> */}
                                                                    {/* <!--begin::Action--> */}
                                                                    <div className="d-flex flex-column flex-md-row justify-content-end gap-3 pb-0 px-0">

                                                                        {/* Show cancel subscription only if not already canceled */}
                                                                        {(!currentUser?.SubscriptionEndDate && (currentUser?.SubscriptionStatus === 'trialing' || currentUser?.SubscriptionStatus === 'active')) && (
                                                                            <button className="btn btn-light btn-active-light-danger" onClick={handleCancel}>
                                                                                Cancel Subscription
                                                                            </button>
                                                                        )}

                                                                        {/* Subtle button for changing plan */}
                                                                        <button
                                                                            size="sm" className="btn btn-outline-primary"
                                                                            onClick={() => openSubscriptionModal('upgrade')}
                                                                        >
                                                                            Change Plan
                                                                        </button>

                                                                        {/* Highlighted button for seat management */}
                                                                        {/* <div className="d-flex gap-3 mt-4"> */}
                                                                        <button className="btn btn-light-primary" onClick={handleAddSeat}>
                                                                            Buy Seat
                                                                        </button>
                                                                        <button className="btn btn-light-danger" disabled={seatStatus.remainingSeats <= 0 ? "disabled" : ""} onClick={handleRemoveUnusedSeats}>
                                                                            Remove Unused Seats {seatStatus.remainingSeats > 0 ? "(" + seatStatus.remainingSeats + ")" : ""}
                                                                        </button>
                                                                        {/* </div> */}


                                                                    </div>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                            </div>
                                                            {/* <!--end::Row--> */}
                                                        </div>
                                                        {/* <!--end::Card body--> */}
                                                    </div>
                                                    {/* <!--end::Billing Summary--> */}

                                                    <UserManagementPanel handleAddSeat={handleAddSeat} />

                                                    {/* <!--begin::Billing History--> */}
                                                    <div className="card">
                                                        {/* <!--begin::Card header--> */}
                                                        <div className="card-header card-header-stretch border-bottom border-gray-200">
                                                            {/* <!--begin::Title--> */}
                                                            <div className="card-title">
                                                                <h3 className="fw-bold m-0">Billing History</h3>
                                                            </div>
                                                            {/* <!--end::Title--> */}
                                                        </div>
                                                        {/* <!--end::Card header--> */}
                                                        {/* <!--begin::Tab Content--> */}
                                                        <div className="tab-content">
                                                            {/* <!--begin::Tab panel--> */}
                                                            <div id="kt_billing_months" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_billing_months">
                                                                {/* <!--begin::Table container--> */}
                                                                <div className="table-responsive">
                                                                    {/* <!--begin::Table--> */}
                                                                    <table className="table table-row-bordered align-middle gy-4 gs-9">
                                                                        <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
                                                                            <tr>
                                                                                <td className="min-w-150px">Date</td>
                                                                                <td className="min-w-250px">Description</td>
                                                                                <td className="min-w-250px">Currency</td>
                                                                                <td className="min-w-150px">Amount</td>
                                                                                <td className="min-w-150px">Invoice</td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="fw-semibold text-gray-600">
                                                                            {billingHistory.map((item, idx) => {

                                                                                let createdAtDate = null;

                                                                                if (item.createdAt?._seconds) {
                                                                                    createdAtDate = new Date(item.createdAt._seconds * 1000);
                                                                                } else if (item.createdAt?.seconds) {
                                                                                    createdAtDate = new Date(item.createdAt.seconds * 1000);
                                                                                } else {
                                                                                    createdAtDate = new Date(item.createdAt);
                                                                                }

                                                                                return (
                                                                                    <tr key={idx}>
                                                                                        <td>{createdAtDate?.toLocaleDateString()}</td>
                                                                                        <td>{item.description}</td>
                                                                                        <td>{typeof item.amount === 'number' && item.amount > 0 ? item.currency?.toUpperCase() : '-'}</td>
                                                                                        <td>{typeof item.amount === 'number' && item.amount > 0 ? item.amount.toFixed(2) : '-'}</td>
                                                                                        <td>
                                                                                            {item.invoiceUrl && (
                                                                                                <a href={item.invoiceUrl} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-light btn-active-light-primary">PDF</a>
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            {item.receiptUrl && (
                                                                                                <a href={item.receiptUrl} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-light btn-active-light-primary">View</a>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}

                                                                        </tbody>
                                                                    </table>
                                                                    {/* <!--end::Table--> */}
                                                                </div>
                                                                {/* <!--end::Table container--> */}
                                                            </div>
                                                            {/* <!--end::Tab panel--> */}
                                                        </div>
                                                        {/* <!--end::Tab Content--> */}
                                                    </div>
                                                    {/* <!--end::Billing Address--> */}
                                                </>
                                            )}

                                            {currentUser?.ExternalPaid && (
                                                <div className="card mb-10">
                                                    <div className="card-body d-flex flex-column align-items-center text-center py-20">
                                                        <i className="ki-outline ki-lock fs-3tx text-gray-400 mb-5"></i>
                                                        <h3 className="fw-bold mb-3 text-gray-800">Billing information unavailable</h3>
                                                        <p className="fs-6 text-gray-600 mb-5">
                                                            You're part of a team subscription managed by:
                                                            <br />
                                                            <strong>{currentUser?.ExternalPaidAdminName}</strong><br />
                                                            <span className="text-muted">{currentUser?.ExternalPaidAdminEmail}</span>
                                                        </p>
                                                        <p className="fs-6 text-gray-500">
                                                            Please contact the admin if you have questions or want to request plan changes or seat management.
                                                        </p>
                                                        <a href={`mailto:${currentUser?.ExternalPaidAdminEmail}`} className="btn btn-sm btn-light-primary mt-3">
                                                            Contact Subscription Owner
                                                        </a>

                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        {/* <!--end::Content--> */}
                                    </div>
                                    {/* <!--end::Content wrapper--> */}
                                    {/* <!--begin::Footer--> */}
                                    <Footer />
                                    {/* <!--end::Footer--> */}
                                </div>
                                {/* <!--end:::Main--> */}
                            </div>
                            {/* {/* <!--end::Wrapper container--> */}

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ProfileDetail;
