import { useLocation } from 'react-router-dom';
import { Timestamp } from "firebase/firestore";

function formatDate(timestamp, notime = false) {
    if (timestamp) {
        // Check if timestamp is already a Firestore Timestamp
        let date;
        if (timestamp instanceof Timestamp) {
            date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        } else if (timestamp._seconds !== undefined) {
            // Reconstruct Firestore Timestamp from raw data
            date = new Timestamp(timestamp._seconds, timestamp._nanoseconds).toDate();
        } else {
            console.error("Invalid timestamp data");
            return "";
        }

        let formattedDate = [];

        if (notime) {
            formattedDate = [
                padTo2Digits(date.getMonth() + 1), // Months are 0-based in JavaScript
                padTo2Digits(date.getDate()),
                date.getFullYear()
            ].join('/');
        }
        else {
            formattedDate = [
                padTo2Digits(date.getMonth() + 1), // Months are 0-based in JavaScript
                padTo2Digits(date.getDate()),
                date.getFullYear()
            ].join('/') + ' ' +
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes())
                ].join(':');
        }

        return formattedDate;
    }
    return ""; // Return empty string if no timestamp
}

function isOverdue(timestamp) {
    if (timestamp) {
        // Check if timestamp is already a Firestore Timestamp
        let date;
        if (timestamp instanceof Timestamp) {
            date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        } else if (timestamp._seconds !== undefined) {
            // Reconstruct Firestore Timestamp from raw data
            date = new Timestamp(timestamp._seconds, timestamp._nanoseconds).toDate();
        } else {
            console.error("Invalid timestamp data");
            return false; // Return false for invalid timestamps
        }

        // Get today's date without time (00:00:00)
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Compare the dates (ignoring time)
        return date < today; // If the date is before today, it is overdue
    }

    return false; // Return false if no timestamp
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

/**
 * Converts bytes to a more readable format (KB or MB).
 * @param {number} bytes - The number of bytes.
 * @return {string} The size in KB or MB rounded to the nearest integer.
 */
function formatBytes(bytes) {
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;

    if (megabytes >= 1) {
        return `${Math.round(megabytes)} mb`;
    } else {
        return `${Math.round(kilobytes)} kb`;
    }
}

function daysFromTimestamp(pastTimestamp) {
    // Check if the passed timestamp is a Firebase Timestamp and convert it
    let pastDate;

    if (pastTimestamp) {
        if (pastTimestamp instanceof Timestamp) {
            pastDate = pastTimestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        } else if (pastTimestamp._seconds !== undefined) {
            // Reconstruct Firestore Timestamp from raw data
            pastDate = new Timestamp(pastTimestamp._seconds, pastTimestamp._nanoseconds).toDate();

            const currentDate = new Date();
            const differenceInTime = currentDate.getTime() - pastDate.getTime();
            const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
            return differenceInDays;
        }
    }
    else {
        return 0;
    }
}

function daysFromDateString(dateStr) {
    const date = new Date(dateStr);
    const today = new Date();
    const differenceInTime = today - date;
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function getFormBadgeClass(formType) {
    switch (formType) {
        case "g1450": return (<span>G-1450</span>);
        case "g1145": return (<span>G-1145</span>);
        case "i140": return (<span>I-140</span>);
        case "i907": return (<span>I-907</span>);
        case "i485": return (<span>I-485</span>);
        case "i765": return (<span>I-765</span>);
        case "i131": return (<span>I-131</span>);
        case "eta9089": return (<span>ETA-9089</span>);
        case "eta9089appendixa": return (<span>ETA-9089 Appendix A</span>);
        case "eta9089finaldetermination": return (<span>ETA-9089 Final Determination</span>);
        case "other": return (<span>other</span>);
        default: return (<span>{formType}</span>);
    }
};


// const { google } = require('googleapis');

// async function getThumbnailUrl(googleDocId, oauthToken) {
//     const auth = new google.auth.OAuth2();
//     auth.setCredentials({ access_token: oauthToken });
//     const drive = google.drive({ version: 'v3', auth });

//     try {
//         const response = await drive.files.get({
//             fileId: googleDocId,
//             fields: 'thumbnailLink',
//         });
//         return response.data.thumbnailLink;
//     } catch (error) {
//         console.error('Error fetching thumbnail URL:', error);
//         throw new Error('Failed to fetch thumbnail URL');
//     }
// }

function getFirstName(fullName) {
    if (typeof fullName !== 'string' || !fullName.trim()) {
        throw new Error('Invalid input: Name must be a non-empty string.');
    }

    const names = fullName.trim().split(/\s+/);
    return names[0];
}

function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const getUserIp = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error("Error fetching IP address:", error);
        return null;
    }
};

/**
 * Check if the logged-in user is the owner of the petition
 * @param {Object} petition - The petition object
 * @param {Object} currentUser - The logged-in user
 * @returns {boolean} - True if the logged-in user is the owner, false otherwise
 */
const isPetitionOwner = (petition, currentUser) => {
    return petition?.userId === currentUser?.uid;
};

// const isUserReadOnly = (petition, userId) => {
//     if (!petition || !petition.sharedWithArray) {
//         console.log("No petition or SharedWithArray found.");
//         return true; // Default to true if petition or array isn't loaded
//     }

//     const sharedUser = petition.sharedWithArray.find(user => user.userId === userId);
//     return sharedUser ? sharedUser.readOnly : true;
// };

const generateColorFromName = (name) => {
    const colors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50'];
    if (name) {
        const charCode = name.charCodeAt(0); // Get ASCII code of first character
        return colors[charCode % colors.length]; // Pick a color based on the char code
    }
    else {
        return colors[4];
    }
};

const formatFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    if (sizeInKB >= 1024) {
        const sizeInMB = sizeInKB / 1024;
        return `${sizeInMB.toFixed(2)} MB`;
    }
    return `${sizeInKB.toFixed(2)} KB`;
};

const isTrialActive = (trialEndDate) => {
    if (!trialEndDate) return false;
    const now = new Date();
    const end = new Date(trialEndDate);
    return now < end;
}

const getDaysLeftInTrial = (trialEndDate) => {
    if (!trialEndDate) return 0;
    const now = new Date();
    const end = new Date(trialEndDate);
    const diffTime = end.getTime() - now.getTime();
    return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0);
}

const customStyles = {
    control: (provided) => ({
        ...provided,
        // display: 'block',
        // width: '100%',
        // // minHeight: 'calc(1.5em + 1.65rem + 2px)',  // Match Bootstrap's large form control height
        padding: '0.825rem 1rem',  // Match padding from form-control-lg
        // fontSize: '1.15rem',  // Match font size
        // fontWeight: 500,
        // // lineHeight: '1.5',
        color: 'var(--bs-gray-700)',
        backgroundColor: 'var(--bs-gray-100)',
        border: '0px',
        borderRadius: '1.25rem',  // Match border radius from form-control-lg
        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        boxShadow: 'none',
        appearance: 'none',  // Remove default browser styling
        backgroundImage: `url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%2378829D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6"/%3e%3c/svg%3e')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 1rem center',
        backgroundSize: '16px 12px',
    }),
    // option: (provided, state) => ({
    //     ...provided,
    //     fontSize: '1.1rem',
    //     fontWeight: 500,
    //     color: state.isSelected ? '#fff' : 'var(--bs-gray-700)',
    //     backgroundColor: state.isSelected ? '#007bff' : '#fff',
    //     '&:hover': {
    //         backgroundColor: state.isSelected ? '#0056b3' : '#f8f9fa',
    //     },
    // }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--bs-gray-700)',
        // color: 'var(--bs-gray-700)',
    }),
    // menu: (provided) => ({
    //     ...provided,
    //     borderRadius: '0.92rem',
    //     boxShadow: '0 0.25rem 0.75rem rgba(0, 0, 0, 0.1)',  // Bootstrap-like shadow
    // }),
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none',  // Hide the default dropdown indicator, since we use a custom background image
    }),
};


export {
    formatDate,
    isOverdue,
    formatBytes,
    daysFromTimestamp,
    useQuery,
    getFormBadgeClass,
    getFirstName,
    formatTimestamp,
    getUserIp,
    isPetitionOwner,
    generateColorFromName,
    formatFileSize,
    isTrialActive,
    getDaysLeftInTrial,
    customStyles
};