import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Home from '../pages/Home/Home';
import PetitionDetail from '../pages/Petition/PetitionDetail';
import { useAuth } from '../contexts/AuthContext';
import ExhibitDetail from '../pages/Exhibit/ExhibitDetail';
import RequestDetail from '../pages/Request/RequestDetail';
import Analytics from '../pages/Analytics/Analytics';
import Community from '../pages/Community/Community';
import ProfileDetail from '../pages/Profile/ProfileDetail';
import CommunityPostPage from '../pages/Community/CommunityPostPage';
import Settings from '../pages/Settings/Settings';
import AdminPage from '../pages/Admin/AdminPage';
import MagicFormPage from '../pages/MagicForm/MagicFormPage';
import ProtectedLayout from '../components/Subscription/ProtectedLayout';
import SubscriptionSuccess from '../components/Subscription/SubscriptionSuccess';

const AppRoutes = () => {
    const { currentUser, loading, loginInProgress } = useAuth();

    if (loading || loginInProgress) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    const isAdmin = currentUser && currentUser.uid === 'QFBK9I6akDOmOSzsPqQwuR49UsI2';

    return (
        <Routes>
            {/* Public routes */}
            <Route path="/" element={!currentUser ? <Login /> : <Navigate to="/home" replace />} />
            <Route path="/login" element={<Login />} />

            {/* Protected routes */}
            <Route
                path="/*"
                element={
                    <ProtectedLayout>
                        <Routes>
                            <Route path="/home" element={<Home />} />
                            <Route path="/analytics" element={<Analytics />} />
                            <Route path="/community" element={<Community />} />
                            <Route path="/community/post/:id" element={<CommunityPostPage />} />
                            <Route path="/admin" element={isAdmin ? <AdminPage /> : <Navigate to="/home" replace />} />
                            <Route path="/petition/:petitionId/settings" element={<Settings />} />
                            <Route path="/profile" element={<ProfileDetail />} />
                            <Route path="/subscription-success" element={<SubscriptionSuccess />} />

                            {/* Original Submission */}
                            <Route path="/petition/:petitionId" element={<PetitionDetail type="OriginalSubmission" />} />
                            <Route path="/petition/:petitionId/exhibit/:exhibitId" element={<ExhibitDetail type="OriginalSubmission" />} />
                            <Route path="/petition/:petitionId/request/:requestId" element={<RequestDetail type="OriginalSubmission" />} />
                            <Route path="/petition/:petitionId/magicform/" element={<MagicFormPage type="OriginalSubmission" />} />

                            {/* RFE */}
                            <Route path="/petition/:petitionId/rfe" element={<PetitionDetail type="RFEResponse" />} />
                            <Route path="/petition/:petitionId/rfe/exhibit/:exhibitId" element={<ExhibitDetail type="RFEResponse" />} />
                            <Route path="/petition/:petitionId/rfe/request/:requestId" element={<RequestDetail type="RFEResponse" />} />
                        </Routes>
                    </ProtectedLayout>
                }
            />
        </Routes>
    );
};

export default AppRoutes;
