// src/layouts/ProtectedLayout.js
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useSubscription } from '../../contexts/SubscriptionContext';

const ProtectedLayout = ({ children }) => {
    const { currentUser, loading } = useAuth();
    const { openSubscriptionModal } = useSubscription();

    useEffect(() => {
        if (!loading && currentUser) {
            const status = currentUser.SubscriptionStatus;
            const isSubscriptionValid = status === 'active' || status === 'trialing';

            if (!isSubscriptionValid) {
                openSubscriptionModal('subscription-required');
            }
        }
    }, [currentUser, loading, openSubscriptionModal]);

    if (loading) return <div className="text-center mt-10">Loading...</div>;

    if (!currentUser) return <Navigate to="/login" />;

    return <>{children}</>;
};

export default ProtectedLayout;
