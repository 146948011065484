// TaskManager.js
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import Loading from '../../components/Loading/Loading';
import { Button, Dropdown, Modal, Form, Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useAuth } from '../../contexts/AuthContext.js';
import {
    fetchTasks,
    createTask,
    deleteTask,
    changeTaskStatus,
    updateTaskPosition,
} from '../../services/ApiService'; // Import necessary API functions
import { generateColorFromName } from '../../utils/Utils';
import { useSubscription } from '../../contexts/SubscriptionContext';

const TaskManager = ({ petition, type, isReadOnly }) => {

    const [petitionId, setPetitionId] = useState(petition.id);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [selectedTasksPriority, setSelectedTasksPriority] = useState('Show All');
    const [selectedTasksStatus, setSelectedTasksStatus] = useState('Show All');
    const [tasks, setTasks] = useState([]);
    const [task, setTask] = useState({
        item: '',
        description: '',
        priority: 'Low',
        status: 'Pending',
    });
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();
    const { openSubscriptionModal } = useSubscription();

    // Load tasks from the API
    const loadTasks = async () => {
        try {
            const fetchedTasks = await fetchTasks(petitionId, type);
            setTasks(fetchedTasks);
        } catch (error) {
            console.error('Failed to load tasks:', error);
        }
    };

    useEffect(() => {
        loadTasks();
    }, [petitionId, type]);

    const handleShowTaskModal = () => setShowTaskModal(true);
    const handleCloseTaskModal = () => setShowTaskModal(false);

    const handleChangeTaskModal = (e) => {
        const { name, value } = e.target;
        setTask((prevTask) => ({ ...prevTask, [name]: value }));
    };

    const handleTasksFilterChange = (e, type) => {
        if (type === 'priority') {
            setSelectedTasksPriority(e.target.value);
        } else if (type === 'status') {
            setSelectedTasksStatus(e.target.value);
        }
    };

    const handleNewTaskClick = () => {
        const isSelfPlan = currentUser?.Plan === 'SELF';
        const isExternalUser = currentUser?.ExternalPaid === true;

        // Feature is locked (SELF plan can't access Tasks)
        if (isSelfPlan) {
            if (isExternalUser) {
                // Inform external user to contact admin
                Swal.fire({
                    icon: 'info',
                    title: 'Feature Unavailable in Current Plan',
                    html: `The Tasks feature is not available in the current subscription plan.<br><br>
                           If you would like to use this feature, please contact your subscription admin:<br><br>
                           <strong>${currentUser?.ExternalPaidAdminName || 'Your Admin'}</strong><br>
                           <a href="mailto:${currentUser?.ExternalPaidAdminEmail}" class="text-primary">${currentUser?.ExternalPaidAdminEmail || ''}</a>`,
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-light-primary'
                    },
                    buttonsStyling: false
                });
            } else {
                // Self plan owner – show upgrade CTA
                Swal.fire({
                    icon: 'info',
                    title: 'Unlock Tasks with Expert Plan',
                    text: 'Tasks help you manage your petition process step by step. Upgrade your plan to start using this feature.',
                    confirmButtonText: 'Upgrade Now',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-light'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        openSubscriptionModal('upgrade');
                    }
                });
            }
        } else {
            // Feature is available – proceed
            handleShowTaskModal();
        }
    };

    // Filter tasks based on selected priority and status
    const filteredTasks = tasks.filter((task) => {
        return (
            (selectedTasksPriority === 'Show All' || task.priority === selectedTasksPriority) &&
            (selectedTasksStatus === 'Show All' ||
                (selectedTasksStatus === 'Hide Done' && task.status !== 'Done') ||
                task.status === selectedTasksStatus)
        );
    });

    // Add a new task
    const handleSubmitTaskModal = async (event) => {
        event.preventDefault();

        const newTask = {
            item: task.item,
            description: task.description,
            priority: task.priority,
            status: task.status,
        };

        try {
            setLoading(true);

            const createdTask = await createTask(petitionId, newTask, type);
            setTasks((prevTasks) => [...prevTasks, createdTask]);

            setTask({
                item: '',
                description: '',
                priority: 'Low',
                status: 'Pending',
            });

            handleCloseTaskModal();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Failed to create task:', error);
        }
    };

    // Change task status
    const handleTaskStatusChange = async (id, newStatus) => {
        try {
            setLoading(true);
            await changeTaskStatus(petitionId, id, newStatus, type);
            setTasks((prevTasks) =>
                prevTasks.map((task) =>
                    task.id === id ? { ...task, status: newStatus } : task
                )
            );
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Failed to update task status:', error);
        }
    };

    // Delete task
    const handleDeleteTask = async (id) => {
        try {
            setLoading(true);
            await deleteTask(petitionId, id, type);
            await loadTasks(); // Re-fetch tasks to ensure correct sequence numbers
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Failed to delete task:', error);
        }
    };

    // Handle drag and drop
    const handleOnDragEndTask = async (result) => {
        const { source, destination } = result;

        if (!destination || source.index === destination.index) return;

        const reorderedTasks = Array.from(tasks);
        const [reorderedItem] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, reorderedItem);

        const updatedTasks = reorderedTasks.map((task, index) => ({
            ...task,
            sequenceNumber: index + 1,
        }));

        setTasks(updatedTasks);

        try {
            await updateTaskPosition(petitionId, reorderedItem.id, source.index + 1, destination.index + 1, type);
        } catch (error) {
            console.error('Error updating task position:', error);
            setTasks(tasks);
        }
    };

    return (
        <>
            <Loading isLoading={loading} />

            {/* <!--begin::Tasks Card--> */}
            <div className="card card-flush h-xl-100">
                {/* <!--begin::Card header--> */}
                <div className="card-header pt-7">
                    {/* <!--begin::Title--> */}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fs-3 fw-bold text-primary">Tasks</span>
                        <span className="text-gray-500 mt-1 fw-semibold fs-6">Your roadmap to a complete US immigration petition</span>
                    </h3>
                    {/* <!--end::Title--> */}
                    {/* Task Filters and Add Task Button */}
                    <div className="card-toolbar">
                        <div className="d-flex flex-stack flex-wrap gap-4">
                            <div className="d-flex align-items-center fw-bold">
                                <div className="text-muted fs-7 me-2">Priority</div>
                                <select
                                    className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                    value={selectedTasksPriority}
                                    onChange={(e) => handleTasksFilterChange(e, 'priority')}
                                >
                                    <option value="Show All">Show All</option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </select>
                            </div>
                            <div className="d-flex align-items-center fw-bold">
                                <div className="text-muted fs-7 me-2">Status</div>
                                <select
                                    className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                    value={selectedTasksStatus}
                                    onChange={(e) => handleTasksFilterChange(e, 'status')}
                                >
                                    <option value="Show All">Show All</option>
                                    <option value="Pending">Pending</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Done">Done</option>
                                    <option value="Hide Done">Hide Done</option>
                                </select>
                            </div>
                            {!isReadOnly ?
                                <Button variant="primary" size="sm" onClick={handleNewTaskClick}>
                                    Add Task
                                </Button>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
                {/* <!--end::Card header--> */}

                {/* <!--begin::Card body--> */}
                {/* Task List with Drag and Drop */}
                <div className="card-body py-0">
                    {(filteredTasks.length > 0 ?
                        <DragDropContext onDragEnd={handleOnDragEndTask}>
                            <Droppable droppableId="tasks">
                                {(provided) => (
                                    <table className="table table-row-dashed align-middle gs-0 gy-6 my-0" {...provided.droppableProps} ref={provided.innerRef}>
                                        <thead>
                                            <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                <th className={`text-start mw-25px d-none ${!isReadOnly ? 'd-lg-block' : ''}`}></th>
                                                <th className="text-start mw-25px">#</th>
                                                <th className="min-w-150px">Item</th>
                                                <th className="text-start pe-3 min-w-100px d-none d-lg-block">Priority</th>
                                                <th className="text-start pe-3 min-w-100px">Status</th>
                                                <th className={`text-center pe-0 min-w-75px ${!isReadOnly ? '' : 'd-none'}`}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="fw-bold text-gray-600">
                                            {filteredTasks.map((task, index) => (
                                                <Draggable key={task.id} draggableId={task.id} index={index}>
                                                    {(provided) => (
                                                        <tr ref={provided.innerRef} {...provided.draggableProps}>
                                                            <td className={`d-none ${!isReadOnly ? 'd-lg-block' : ''}`} {...provided.dragHandleProps} key={task.id}>
                                                                <i className="ki-solid ki-abstract-30 text-gray-700"></i>
                                                            </td>
                                                            <td>{task.sequenceNumber}</td>
                                                            <td>
                                                                <span className="text-gray-800 fw-bold fs-6 d-block">{task.item}</span>
                                                                <span className="text-gray-500 fw-semibold fs-7">{task.description}</span>
                                                            </td>
                                                            <td className="text-start d-none d-lg-block">
                                                                <span className={`badge py-3 px-4 fs-7 badge-light-${task.priority === 'High' ? 'danger' : task.priority === 'Medium' ? 'warning' : 'success'}`}>
                                                                    {task.priority}
                                                                </span>
                                                            </td>
                                                            <td className="text-start">
                                                                <span className={`badge py-3 px-4 fs-7 badge-light-${task.status === 'Done' ? 'success' : task.status === 'In Progress' ? 'primary' : 'danger'}`}>
                                                                    {task.status}
                                                                </span>
                                                            </td>
                                                            <td className={`text-center pe-0 min-w-75px ${!isReadOnly ? '' : 'd-none'}`}>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {task.status !== 'Pending' && (
                                                                            <Dropdown.Item onClick={() => handleTaskStatusChange(task.id, 'Pending')}>
                                                                                Mark as Pending
                                                                            </Dropdown.Item>
                                                                        )}
                                                                        {task.status !== 'In Progress' && (
                                                                            <Dropdown.Item onClick={() => handleTaskStatusChange(task.id, 'In Progress')}>
                                                                                Mark as In Progress
                                                                            </Dropdown.Item>
                                                                        )}
                                                                        {task.status !== 'Done' && (
                                                                            <Dropdown.Item onClick={() => handleTaskStatusChange(task.id, 'Done')}>
                                                                                Mark as Done
                                                                            </Dropdown.Item>
                                                                        )}
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item onClick={() => handleDeleteTask(task.id)} className="text-danger">
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext >
                        :
                        <div style={{ display: 'grid', placeItems: 'center', height: '60' }}>
                            <img height="300px" src="/assets/media/illustrations/21.png" alt="No files found"></img>
                        </div>
                    )}
                </div >
            </div >
            {/* <!--end::Tasks Card--> */}

            {/* Add Task Modal */}
            <Modal show={showTaskModal} onHide={handleCloseTaskModal}>
                <form onSubmit={handleSubmitTaskModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Task</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3 fv-row">
                            <Form.Label className="form-label mb-3 required">Item</Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                name="item"
                                placeholder="Enter task item"
                                value={task.item}
                                onChange={handleChangeTaskModal}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 fv-row">
                            <Form.Label className="form-label mb-3">Description (Optional)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                className="form-control form-control-lg form-control-solid"
                                name="description"
                                placeholder="Enter a small description"
                                value={task.description}
                                onChange={handleChangeTaskModal}
                            />
                        </Form.Group>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group className="fv-row">
                                    <Form.Label className="form-label mb-3 required">Priority</Form.Label>
                                    <Form.Select
                                        className="form-control form-control-lg form-control-solid"
                                        name="priority"
                                        value={task.priority}
                                        onChange={handleChangeTaskModal}
                                        required
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="fv-row">
                                    <Form.Label className="form-label mb-3 required">Status</Form.Label>
                                    <Form.Select
                                        className="form-control form-control-lg form-control-solid"
                                        name="status"
                                        value={task.status}
                                        onChange={handleChangeTaskModal}
                                        required
                                    >
                                        <option value="Pending">Pending</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Done">Done</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-light-secondary" onClick={handleCloseTaskModal}>
                            Cancel
                        </Button>
                        <Button className="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default TaskManager;
