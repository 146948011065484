// src/contexts/SubscriptionContext.js
import { createContext, useState, useContext } from 'react';
import SubscriptionModal from '../components/Subscription/SubscriptionModal';

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [modalType, setModalType] = useState('subscription-required'); // more generic than 'trial-expired'

    const openSubscriptionModal = (type = 'subscription-required') => {
        setModalType(type);
        setShowSubscriptionModal(true);
    };

    const closeSubscriptionModal = () => {
        setShowSubscriptionModal(false);
    };

    return (
        <SubscriptionContext.Provider value={{ openSubscriptionModal, closeSubscriptionModal }}>
            {children}
            {showSubscriptionModal && (
                <SubscriptionModal type={modalType} onClose={closeSubscriptionModal} />
            )}
        </SubscriptionContext.Provider>
    );
};

export const useSubscription = () => useContext(SubscriptionContext);
