import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
// import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { formatDate, formatBytes, getFormBadgeClass } from '../../utils/Utils.js'
import { fetchForms, deleteForm, addForm } from '../../services/ApiService.js';
import Loading from '../../components/Loading/Loading.js';

function PetitionForms({ petitionId, petitionFolderId, type, isReadOnly }) {
    const { logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [newFormModalIsOpen, setNewFormModalIsOpen] = React.useState(false);
    const [updateForms, setUpdateForms] = useState(false); // New state to trigger re-fetching
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [forms, setForms] = useState([]);

    const initialFormState = {
        formType: ""
    };

    const [formState, setFormState] = useState(initialFormState);

    function openNewFormModal() {
        setNewFormModalIsOpen(true);
    }

    function closeNewFormModal() {
        setNewFormModalIsOpen(false);
    }

    const deleteFormConfirmation = async (formId) => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFormCall(formId);
            }
        });
    }

    const deleteFormCall = async (formId) => {
        setLoading(true);

        try {
            await deleteForm(petitionId, formId, type);
            setUpdateForms(prev => !prev);
        } catch (error) {
            console.error("Error deleting form:", error);
        } finally {
            setLoading(false);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            // console.log('Accepted files:', acceptedFiles);
            setFilesToUpload(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const handleInputChange = (event) => {
        const target = event.target;
        let value = target.value;

        // If the input type is 'radio' and the target name is 'isDocs', convert value to string for correct comparison.
        if (target.type === 'radio' && target.name === 'isDocs') {
            value = target.value;
        }

        setFormState(prev => ({
            ...prev,
            [target.name]: value
        }));
    };

    const filesElements = filesToUpload.map(fileToUpload => (
        <li key={fileToUpload.name}>
            {fileToUpload.name} - {fileToUpload.size} bytes
        </li>
    ));

    useEffect(() => {
        async function fetchData() {
            // console.log('fetchData');
            await fetchFormsCall(petitionId, setForms);
        }

        fetchData();

    }, [petitionId, updateForms]);

    async function fetchFormsCall(petitionId, setForms) {
        const formsList = await fetchForms(petitionId, type)

        setForms(formsList);
    }

    const handleNewFormSubmit = async (event) => {
        event.preventDefault();  // Prevent the default form submission behavior
        setLoading(true);

        if (filesToUpload.length > 0) {
            for (const file of filesToUpload) {  // Process each file

                try {
                    await addForm(file, petitionId, formState.formType, petitionFolderId, type);

                    setUpdateForms(prev => !prev);
                    setLoading(false);
                    closeNewFormModal();
                    setFormState(initialFormState);
                    setFilesToUpload([]);  // Clear the files array        

                } catch (error) {
                    setLoading(false);

                    console.error(error);
                    if (error.message === 'Unauthorized') {
                        logout();
                    } else {
                        Swal.fire("Error", 'An error occurred while creating the file. Please try again.', "error");
                    }
                }
            }
        } else {
            setLoading(false);
            Swal.fire("info", 'Please upload at least one file.', "info");
        }
    }

    // const getFormBadgeClass = (formType) => {
    //     switch (formType) {
    //         case "g1450": return (<span>G-1450</span>);
    //         case "g1145": return (<span>G-1145</span>);
    //         case "i140": return (<span>I-140</span>);
    //         case "i907": return (<span>I-907</span>);
    //         case "i485": return (<span>I-485</span>);
    //         case "i765": return (<span>I-765</span>);
    //         case "i131": return (<span>I-131</span>);
    //         case "eta9089": return (<span>ETA-9089</span>);
    //         case "eta9089appendixa": return (<span>ETA-9089 Appendix A</span>);
    //         case "eta9089finaldetermination": return (<span>ETA-9089 Final Determination</span>);
    //         case "other": return (<span>other</span>);
    //         default: return (<span>{formType}</span>);
    //     }
    // };

    return (
        <>
            <Loading isLoading={loading} />
            <Modal show={newFormModalIsOpen} onHide={closeNewFormModal} className="modal-lg">
                <form id="uploadForm" onSubmit={handleNewFormSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*  {/* <!--begin::Modal body--> */}
                        <div className="scroll-y">
                            {/*  {/* <!--begin::Stepper--> */}
                            <div className="d-flex flex-column">
                                {/*  {/* <!--begin::Form--> */}
                                {/* <form className="mx-auto mw-600px w-100 py-10" noValidate="novalidate" id="kt_create_account_form"> */}
                                {/*  {/* <!--begin::Step 2--> */}
                                <div data-kt-stepper-element="content">
                                    {/*  {/* <!--begin::Wrapper--> */}
                                    <div className="w-100">
                                        {/*  {/* <!--begin::Heading--> */}
                                        <div className="pb-10 pb-lg-15">
                                            {/*  {/* <!--begin::Title--> */}
                                            <h2 className="fw-bold text-gray-900">Form Details</h2>
                                            {/*  {/* <!--end::Title--> */}
                                            {/*  {/* <!--begin::Notice--> */}
                                            <div className="text-muted fw-semibold fs-6">If you need more info, please contact us. </div>
                                            {/*  {/* <!--end::Notice--> */}
                                        </div>
                                        {/*  {/* <!--end::Heading--> */}

                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-0 fv-row">
                                            <label className="d-flex align-items-center form-label mb-5 required">Form Type
                                            </label>
                                            <select
                                                name="formType"
                                                className="form-select form-select-lg form-select-solid"
                                                value={formState.formType}
                                                onChange={handleInputChange}
                                                data-control="select2"
                                                data-placeholder="Select..."
                                                data-allow-clear="true"
                                                data-hide-search="true"
                                                required
                                            >
                                                <option value="">Select type...</option>
                                                <option value="g1450">G-1450</option>
                                                <option value="g1145">G-1145</option>
                                                <option value="i140">I-140</option>
                                                <option value="i907">I-907</option>
                                                <option value="i485">I-485</option>
                                                <option value="i765">I-765</option>
                                                <option value="i131">I-131</option>
                                                <option value="eta9089">ETA-9089</option>
                                                <option value="eta9089appendixa">ETA-9089 Appendix A</option>
                                                <option value="eta9089finaldetermination">ETA-9089 Final Determination</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                        {/* <!--end::Input group--> */}

                                        < div className="mb-10 fv-row">
                                            {/*  {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3 required">File</label>
                                            {/*  {/* <!--end::Label--> */}
                                            {/*  {/* <!--begin::Input--> */}
                                            {/* <input type="file" id="inputUploadFile" required /> */}
                                            <div {...getRootProps()} className="dropzone">
                                                <input {...getInputProps()} name="files" />
                                                {isDragActive ?
                                                    <p>Drop the file here ...</p> :
                                                    <p>Drag 'n' drop some file here, or click to select file</p>
                                                }
                                            </div>
                                            <ul>{filesElements}</ul>
                                            {/* <input type="text" className="form-control form-control-lg form-control-solid" name="account_name" placeholder="" value="" /> */}
                                            {/*  {/* <!--end::Input--> */}
                                        </div>
                                    </div>
                                    {/*  {/* <!--end::Wrapper--> */}
                                </div>
                                {/*  {/* <!--end::Step 2--> */}
                                {/* </form> */}
                                {/*  {/* <!--end::Form--> */}
                            </div>
                            {/*  {/* <!--end::Stepper--> */}
                        </div>
                        {/*  {/* <!--end::Modal body--> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn btn-light-secondary" onClick={closeNewFormModal}>
                            Close
                        </span>
                        <button className="btn btn-primary" type="submit">
                            Submit
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <div className="flex-lg-row-fluid">
                {/*  {/* {/* <!--begin::Card Widget 22--> */}
                <div className="card card-flush border-0">
                    {/*  {/* {/* <!--begin::Header--> */}
                    <div className="card-header pt-7">
                        {/*  {/* {/* <!--begin::Title--> */}
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fs-3 fw-bold text-primary">Forms</span>
                            <span className="text-gray-500 mt-1 fw-semibold fs-6">I-140, ETA-9089, G-1145, etc. </span>
                        </h3>
                        {/*  {/* {/* <!--end::Title--> */}
                        {/*  {/* {/* <!--begin::Toolbar--> */}
                        <div className="card-toolbar">
                            {!isReadOnly ? (
                                <Button variant="primary" size="sm" onClick={openNewFormModal}>
                                    New Form
                                </Button>
                            ) : (<></>)}
                        </div>
                        {/*  {/* {/* <!--end::Toolbar--> */}
                    </div>
                    {/*  {/* {/* <!--end::Header--> */}
                    {/*  {/* {/* <!--begin::Body--> */}
                    <div className="card-body pt-6">
                        {/*  {/* {/* <!--begin::Table container--> */}
                        {(forms.length > 0 ?
                            <div className="table-responsive">
                                {/*  {/* {/* <!--begin::Table--> */}
                                <table className="table table-row-dashed align-middle gs-0 gy-6 my-0">
                                    {/*  {/* {/* <!--begin::Table head--> */}
                                    <thead>
                                        <tr className="fs-7 fw-bold text-gray-500">
                                            <th className="min-w-150px text-start">NAME</th>
                                            <th className="w-200px text-start">TYPE</th>
                                            <th className="text-end">SIZE</th>
                                            <th className="text-end min-w-100px text-start">CREATED AT</th>
                                            {/* <th className="p-0 pb-3 min-w-100px text-center">MODIFIED AT</th> */}
                                            <th className="min-w-100px text-center">ACTION</th>
                                        </tr>
                                    </thead>
                                    {/*  {/* {/* <!--end::Table head--> */}
                                    {/*  {/* {/* <!--begin::Table body--> */}
                                    <tbody className="fw-semibold text-gray-600" style={{ whiteSpace: 'nowrap' }}>
                                        {forms.map(form => (
                                            <tr key={form.id} className={`${form.filesCount === 0 ? 'text-danger' : ''}`}>
                                                <td className="text-start">
                                                    <div className="d-flex align-items-center">
                                                        <a
                                                            href={form.isFirestore
                                                                ? form.storageUrl // Use Firebase Storage URL if isFirestore is true
                                                                : `https://drive.google.com/file/d/${form.googleDocId}/view`} // Otherwise, use Google Drive URL
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            <span className="text-primary fs-6 d-block">{form.fileName}</span>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <span className={`badge py-3 px-4 fs-7 badge-light-info`}>{getFormBadgeClass(form.formType)}</span>
                                                </td>
                                                <td className="text-end">
                                                    <span>{formatBytes(form.size)}</span>
                                                </td>
                                                <td className="text-end">
                                                    <span>{formatDate(form.createdAt)}</span>
                                                    {/* <span>{form.createdAt}</span> */}
                                                </td>
                                                {/* <td className="text-center">
                                            <span>{formatDate(form.modifiedAt)}</span>
                                        </td> */}
                                                <td className="text-center" >
                                                    {!isReadOnly ? (
                                                        <span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
                                                            <i className="ki-outline ki-delete-folder fs-2 text-danger" onClick={() => deleteFormConfirmation(form.id)}>
                                                            </i>
                                                        </span>
                                                    ) : (<></>)}

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    {/*  {/* {/* <!--end::Table body--> */}
                                </table>
                            </div>
                            :
                            <div style={{ display: 'grid', placeItems: 'center', height: '60' }}>
                                <img height="300px" src="/assets/media/illustrations/21.png" alt="No files found"></img>
                            </div>
                        )}
                        {/*  {/* {/* <!--end::Table--> */}
                        {/*  {/* {/* <!--begin::Separator--> */}
                        {/* <div className="separator separator-dashed border-gray-200 mb-n4"></div> */}
                        {/*  {/* {/* <!--end::Separator--> */}
                    </div>
                    {/*  {/* {/* <!--end: Card Body--> */}
                </div>
                {/*  {/* {/* <!--end::Card Widget 22--> */}
            </div>
        </>
    );
}

export default PetitionForms;
