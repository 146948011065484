import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const SubscriptionSuccess = () => {
    const { refreshUserFromFirestore } = useAuth(); // assuming you have this
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Optionally reload user data after subscription
        if (refreshUserFromFirestore) {
            refreshUserFromFirestore(); // reloads user subscription state
        }

        // Auto-redirect after few seconds
        const timer = setTimeout(() => {
            navigate('/home'); // or wherever you want to send them
        }, 5000);

        return () => clearTimeout(timer);
    }, [navigate, refreshUserFromFirestore]);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 px-4 text-center">
            <h1 className="fw-bold mb-4 text-success">🎉 Subscription Successful!</h1>
            <p className="fs-5 mb-4">
                Thank you for subscribing to Visa Genius. Your plan is now active.
            </p>
            <p className="text-muted mb-4">You will be redirected to your dashboard shortly.</p>
            <button className="btn btn-primary" onClick={() => navigate('/home')}>
                Go to Petition Page Now
            </button>
        </div>
    );
};

export default SubscriptionSuccess;
