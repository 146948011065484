import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import Chart from "react-apexcharts";
import Joyride from 'react-joyride';
import { Timestamp } from "firebase/firestore";
import { useParams, Link, useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useAuth } from '../../contexts/AuthContext.js';
import { useExhibitContext } from '../../contexts/ExhibitContext.js';
import { daysFromTimestamp, useQuery, getFirstName } from '../../utils/Utils.js'
import Loading from '../../components/Loading/Loading.js';
import PetitionForms from './PetitionForms';
import PetitionCoverLetter from './PetitionCoverLetter';
import PetitionRFE from './PetitionRFE';
import PetitionPlan from './PetitionPlan';
import PetitionExhibits from './PetitionExhibits.js';
import PetitionMyPetition from './PetitionMyPetition';
import { fetchPetition, fetchExhibits, deleteExhibit, renameExhibit, addExhibit, calculateExhibitDistribution, addReceiptNumber, fetchPetitionTimeline, fetchPetitionExhibitsAnalysis } from '../../services/ApiService.js';
import FabButton from '../../components/FAB/FabButton';
import ChatWindow from '../../components/AskGenius/ChatWindow';
import RequestManager from '../../components/RequestManager/RequestManager.js';
import TaskManager from '../../components/TaskManager/TaskManager.js';
import DocumentsManager from '../../components/DocumentsManager/DocumentsManager.js';

function PetitionDetail({ type }) {
    const { petitionId } = useParams();
    const [petition, setPetition] = useState(null);
    const [exhibits, setExhibits] = useState([]);
    const [loading, setLoading] = useState(false);
    const { logout, currentUser } = useAuth();
    const [updateExhibits, setUpdateExhibits] = useState(false); // New state to trigger re-fetching
    const [modalExhibitIsOpen, setModalExhibitIsOpen] = React.useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [filteredExhibits, setFilteredExhibits] = useState(exhibits);
    const [activeTab, setActiveTab] = useState('general');
    const query = useQuery();
    const isShowTour = query.get('showTour') === '1';
    const [runTour, setRunTour] = useState(isShowTour);
    const [isReadOnly, setIsReadOnly] = useState(false); // NEW: read-only status

    const [petitionAnalysis, setPetitionAnalysis] = useState({
        passedAD: false,
        passedEA: false,
        reqEACount: 0,
        prong1percent: 0,
        prong2percent: 0,
        prong3percent: 0
    });

    const [isModalOpen, setModalOpen] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    // useEffect(() => {
    //     console.log("useEffect 1")
    //     if (petition && (!petition.visaType || petition.visaType == "EB2NIW")) {
    //         console.log("useEffect 2")
    //         async function fetchData() {
    //             console.log("useEffect 3")

    //             const petitionExhibitsAnalysis = await fetchPetitionExhibitsAnalysis(petitionId, type);

    //             if (petitionExhibitsAnalysis) {
    //                 const data = petitionExhibitsAnalysis.analysisData;

    //                 setPetitionAnalysis({
    //                     passedAD: data.passedAD,
    //                     passedEA: data.uniqueEACriteria >= 3,
    //                     reqEACount: data.uniqueEACriteria,
    //                     prong1percent: Math.round((data.uniqueProng1Criteria / 6) * 100),
    //                     prong2percent: Math.round((data.uniqueProng2Criteria / 15) * 100),
    //                     prong3percent: Math.round((data.uniqueProng3Criteria / 5) * 100)
    //                 });
    //             }
    //         }

    //         fetchData();
    //     }

    // }, [petitionId]);

    const [formStateReceiptNumber, setFormStateReceiptNumber] = useState({
        receiptNumber: ''
    });

    const handleInputChangeReceiptNumber = (event) => {
        const { name, value } = event.target;
        setFormStateReceiptNumber(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // Inside the component function
    const { triggerUpdate } = useExhibitContext();

    const navigate = useNavigate();

    const [checkedItems, setCheckedItems] = useState({
        descriptionEndeavor: 0,
        documentaryEvidence: 0,

        qualifications: 0,
        futureActivityPlans: 0,
        progressTowardsEndeavor: 0,
        interestOfStakeholders: 0,

        laborCertificationImpracticality: 0,
        benefitToUSDespiteWorkers: 0,
        urgencyAndJobCreation: 0
    });

    useEffect(() => {
        async function fetchData() {
            // Fetch petition details
            const fetchedPetition = await fetchPetitionCall(petitionId);
            if (fetchedPetition) {

                // NEW: Determine if current user is read-only
                const userEntry = fetchedPetition.SharedWithArray?.find(user => user.userId === currentUser.uid);
                setIsReadOnly(userEntry ? userEntry.readOnly : false);

                setPetition(fetchedPetition);

                // Fetch exhibits
                fetchExhibitsCall(petitionId, setExhibits);

                // Fetch and sort timeline
                const timeline = await fetchPetitionTimeline(petitionId);
                // const sortedTimeline = timeline.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                const sortedTimeline = timeline.sort((a, b) => {

                    const dateA = new Timestamp(a.timestamp._seconds, a.timestamp._nanoseconds).toDate();
                    const dateB = new Timestamp(b.timestamp._seconds, b.timestamp._nanoseconds).toDate();
                    // const dateA = new Date(a.timestamp.seconds * 1000); // Convert seconds to milliseconds
                    // const dateB = new Date(b.timestamp.seconds * 1000);
                    return dateB - dateA;
                });

                // Update petition with sorted timeline
                const updatedPetition = {
                    ...fetchedPetition,
                    Timeline: sortedTimeline
                };

                if (fetchedPetition && (!fetchedPetition.visaType || fetchedPetition.visaType == "EB2NIW")) {
                    console.log("useEffect 2")

                    const petitionExhibitsAnalysis = await fetchPetitionExhibitsAnalysis(petitionId, type);

                    if (petitionExhibitsAnalysis) {
                        const data = petitionExhibitsAnalysis.analysisData;

                        setPetitionAnalysis({
                            passedAD: data.passedAD,
                            passedEA: data.uniqueEACriteria >= 3,
                            reqEACount: data.uniqueEACriteria,
                            prong1percent: Math.round((data.uniqueProng1Criteria / 6) * 100),
                            prong2percent: Math.round((data.uniqueProng2Criteria / 15) * 100),
                            prong3percent: Math.round((data.uniqueProng3Criteria / 5) * 100)
                        });
                    }
                }

                setPetition(updatedPetition);
            }
        }

        fetchData();
    }, [petitionId, updateExhibits]); // Assume fetchExhibitsCall may change updateExhibits

    // Handle Exhibit Distribution Calculation
    useEffect(() => {
        if (petition && (!petition.visaType || petition.visaType == "EB2NIW")) {
            async function fetchDistribution() {
                try {
                    await calculateExhibitDistributionCall(petitionId);
                } catch (error) {
                    console.log(error);
                }
            }

            if (petitionId) {
                fetchDistribution();
            }
        }
    }, [petitionId, petition]);

    // Filter Exhibits based on the active item
    useEffect(() => {
        if (activeItem) {
            setFilteredExhibits(exhibits.filter(exhibit => exhibit.exhibitType === activeItem));
        } else {
            setFilteredExhibits(exhibits);
        }
    }, [activeItem, exhibits]);


    async function fetchPetitionCall(petitionId) {
        try {
            const result = await fetchPetition(petitionId);
            return result;  // Return the fetched data
        } catch (error) {
            console.error('Error fetching petition:', error);
            return null;  // Return null in case of an error
        }
    }

    function openModalExhibit() {
        setModalExhibitIsOpen(true);
    }

    function closeModalExhibit() {
        setModalExhibitIsOpen(false);
    }

    const [formState, setFormState] = useState({
        exhibitName: '',
        exhibitType: ''
    });

    const handleInputChange = (event) => {
        // console.log('handleInputChange');
        const { name, value } = event.target;
        setFormState(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmitExhibit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await addExhibit(formState, petitionId, type)
                .then((result) => {
                    setLoading(false);

                    let exhibitUrl = `/petition/${petitionId}/exhibit/${result.exhibitId}`;
                    if (type == "RFEResponse") {
                        exhibitUrl = `/petition/${petitionId}/rfe/exhibit/${result.exhibitId}`;
                    }

                    if (exhibits.length == 0) exhibitUrl += '?showTour=1'
                    navigate(exhibitUrl);
                })
                .catch((error) => {
                    setLoading(false);

                    console.error(error);
                    if (error.message === 'Unauthorized') {
                        logout();
                    } else {
                        Swal.fire("Error", "An error occurred while creating exhibit. Please try again.", "error");
                    }

                });

        } catch (error) {
            setLoading(false);

            console.error(error);
            if (error.message === 'Unauthorized') {
                logout();
            } else {
                Swal.fire("Error", "An error occurred while creating exhibit. Please try again.", "error");
            }
        }
    };

    async function fetchExhibitsCall(petitionId, setExhibits) {
        const exhibitsList = await fetchExhibits(petitionId, type)

        setExhibits(exhibitsList);
        setFilteredExhibits(exhibitsList);
    }

    const exhibitTypes = [
        // { name: "CoverLetter", icon: "ki-sms", desc: "Cover Letter" },
        // { name: "Plan", icon: "ki-abstract-23", desc: "Plan" },
        { name: "Certificate", icon: "ki-medal-star", desc: "Certificates", style: "badge-light-success" },
        { name: "Letter", icon: "ki-file", desc: "Letters", style: "badge-light-info" },
        { name: "OtherExhibit", icon: "ki-document", desc: "Other Exhibits", style: "badge-light-primary" }
    ];

    async function calculateExhibitDistributionCall() {
        return calculateExhibitDistribution(petitionId, type)
            .then((result) => {
                if (result) {
                    setCheckedItems(prev => ({
                        ...prev,
                        ...result
                    }));
                }
            })
            .catch((error) => {
                console.error('Error fetching petition:', error);
                return null;
            });


    }

    const handleMenuItemClick = (name) => {
        if (activeItem === name) {
            setActiveItem(null);
        } else {
            setActiveItem(name);
        }
    };

    const getExhibitCount = (type) => {
        return exhibits.filter(exhibit => exhibit.exhibitType === type).length;
    };

    const deleteExhibitCall = async (exhibitId, type) => {
        setLoading(true);

        // Create a reference to the document
        // const exhibitDocRef = doc(db, `Petitions/${petitionId}/Exhibits`, exhibitId);
        try {
            await deleteExhibit(petitionId, exhibitId, type);

            setUpdateExhibits(prev => !prev); // Toggle the state to trigger re-fetching of exhibits

            triggerUpdate();

            calculateExhibitDistributionCall();

        } catch (error) {
            console.error("Error deleting exhibit from Firestore:", error);
        } finally {
            setLoading(false);
        }
    };

    const renameExhibitCall = async (exhibitId, newname, type) => {
        setLoading(true);
        try {
            await renameExhibit(petitionId, exhibitId, newname, type);

            setUpdateExhibits(prev => !prev); // Toggle the state to trigger re-fetching of exhibits
        } catch (error) {
            console.error("Error deleting exhibit from Firestore:", error);
        } finally {
            setLoading(false);
        }
    };

    // Callback function to update petition state
    const updatePetition = (updatedPetition) => {
        setPetition((prevPetition) => ({
            ...prevPetition,
            ...updatedPetition,
        }));
    };

    const getBadgeClass = (exhibitType) => {
        switch (exhibitType) {
            case "CoverLetter":
                return (
                    <>
                        <span className={`badge py-3 px-4 fs-7 badge-light-info`}>Cover Letter</span>
                    </>
                );
            case "Plan":
                return (
                    <>
                        <span className={`badge py-3 px-4 fs-7 badge-light-primary`}>{exhibitType}</span>
                    </>
                );
            case "RegularEvidence":
                return (
                    <>
                        <span className={`badge py-3 px-4 fs-7 badge-light-primary`}>Other Exhibit</span>
                    </>
                );
            case "OtherExhibit":
                return (
                    <>
                        <span className={`badge py-3 px-4 fs-7 badge-light-primary`}>Other Exhibit</span>
                    </>
                );
            case "Certificate":
                return (
                    <>
                        <span className={`badge py-3 px-4 fs-7 badge-light-success`}>{exhibitType}</span>
                    </>
                );
            case "Letter":
                return (
                    <>
                        <span className={`badge py-3 px-4 fs-7 badge-light-info`}>{exhibitType}</span>
                    </>
                );
            default:
                return (
                    <>
                        <span className={`badge py-3 px-4 fs-7 badge-light-secondary`}>{exhibitType}</span>
                    </>
                );
        }
    };

    const validateReceiptNumber = (receiptNumber) => {
        const regex = /^[A-Za-z]{3}\d{10}$/;
        return regex.test(receiptNumber);
    };

    const handleSubmitReceiptNumber = async (event) => {
        event.preventDefault();
        let receiptNumber = formStateReceiptNumber.receiptNumber;

        if (validateReceiptNumber(receiptNumber)) {
            try {
                setLoading(true);

                console.log('Submitting Receipt Number:', receiptNumber);
                // Assume addReceiptNumber is a function that takes the receipt number and petition ID and does something with them
                await addReceiptNumber(receiptNumber, petitionId);

                const timeline = await fetchPetitionTimeline(petitionId);

                const sortedTimeline = timeline.sort((a, b) => {

                    const dateA = new Timestamp(a.timestamp._seconds, a.timestamp._nanoseconds).toDate();
                    const dateB = new Timestamp(b.timestamp._seconds, b.timestamp._nanoseconds).toDate();
                    // const dateA = new Date(a.timestamp.seconds * 1000); // Convert seconds to milliseconds
                    // const dateB = new Date(b.timestamp.seconds * 1000);
                    return dateB - dateA;
                });

                const updatedPetition = {
                    ...petition,
                    receiptNumber: receiptNumber,
                    Timeline: sortedTimeline
                };

                setLoading(false);

                setPetition(updatedPetition);
            } catch (error) {
                setLoading(false);
                console.error('Error submitting receipt number:', error);
            }
        } else {
            Swal.fire("Error", 'Invalid receipt number format. Please ensure it starts with three letters followed by ten digits.', "error");
        }
    };

    const steps = [
        {
            target: '#cardPetitionDetails',
            content: 'This area provides details about your petition, including your Visa Genius Ranking. The ranking shows how your petition compares to others on Visa Genius by evaluating your coverage of each Prong.',
            placement: 'top'
        },
        {
            target: '#rowEvidenceDistribution',
            content: 'This section displays the distribution of your exhibits across the Prongs. It helps you identify if your evidence is balanced or if there are Prongs that need more attention.',
            placement: 'bottom'
        },
        {
            target: '#cardReceiptNumber',
            content: 'Already submitted your petition? Enter your Receipt Number here to track your Petition\'s Timeline and receive notifications about any updates.',
            placement: 'bottom'
        },
        {
            target: '#navMenuForms',
            content: 'Use this section to upload and organize all necessary forms related to your petition. Keep everything in one convenient place.',
            placement: 'bottom'
        },
        {
            target: '#navMenuCoverLetter',
            content: 'Draft your petition’s Cover Letter right here on Visa Genius. Our tools check for grammar errors, offer text suggestions, and analyze sentiment and polarity by chapter, making it easy to refine your letter.',
            placement: 'bottom'
        },
        {
            target: '#navMenuPlan',
            content: 'Create your Business, Professional, or Research Plan within Visa Genius. Write and refine your plans using our comprehensive tools to streamline the process.',
            placement: 'bottom'
        },
        {
            target: '#navMenuExhibits',
            content: 'The core of Visa Genius lies here. Submit all your evidence, categorize each item by eligibility and Prong criteria, and tell Visa Genius why each piece of evidence is important.',
            placement: 'bottom'
        },
    ];

    const prong1RadialBarOptions = {

        series: [petitionAnalysis.prong1percent],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                offsetY: 0
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                        margin: 0,
                        size: "55%"
                    },
                    dataLabels: {
                        // showOn: "always",
                        value: {
                            show: false,
                        }
                    },
                }
            },
            stroke: {
                lineCap: "round",
            },
            colors: ['#DC3545'],
            labels: [petitionAnalysis.prong1percent + '%'],
        }
    };

    const prong2RadialBarOptions = {
        series: [petitionAnalysis.prong2percent],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                offsetY: 0
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                        margin: 0,
                        size: "55%"
                    },
                    dataLabels: {
                        // showOn: "always",
                        value: {
                            show: false,
                        }
                    },
                }
            },
            stroke: {
                lineCap: "round",
            },
            colors: ['#FFC107'],
            labels: [petitionAnalysis.prong2percent + '%'],
        }
    };

    const prong3RadialBarOptions = {

        series: [petitionAnalysis.prong3percent],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                offsetY: 0
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                        margin: 0,
                        size: "55%"
                    },
                    dataLabels: {
                        // showOn: "always",
                        value: {
                            show: false,
                        }
                    },
                }
            },
            stroke: {
                lineCap: "round",
            },
            colors: ['#17A2B8'],
            labels: [petitionAnalysis.prong3percent + '%'],
        }
    };

    if (!petition) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <FabButton onClick={toggleChat} />
            <ChatWindow isChatOpen={isChatOpen} toggleChat={toggleChat} petitionId={petitionId} petitionerName={getFirstName(petition.petitionerName)} />

            <Joyride
                continuous
                run={runTour}
                steps={steps}
                scrollToFirstStep
                showProgress
                showSkipButton
                styles={{
                    options: {
                        zIndex: 1000,
                        primaryColor: '#DC3545'
                    },
                }}
                callback={(data) => {
                    if (data.status === 'finished' || data.status === 'skipped') {
                        setRunTour(false);
                    }
                }}
            />

            <Loading isLoading={loading} />

            <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
                <Modal show={modalExhibitIsOpen} onHide={closeModalExhibit}>
                    <form onSubmit={handleSubmitExhibit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Exhibit</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <!--begin::Modal body--> */}
                            <div className="scroll-y">
                                {/* <!--begin::Stepper--> */}
                                <div className="d-flex flex-column">
                                    {/* <!--begin::Step 2--> */}
                                    <div data-kt-stepper-element="content">
                                        {/* <!--begin::Wrapper--> */}
                                        <div className="w-100">
                                            {/* <!--begin::Heading--> */}
                                            <div className="pb-10 pb-lg-15">
                                                {/* <!--begin::Title--> */}
                                                <h2 className="fw-bold text-gray-900">Exhibit Details</h2>
                                                {/* <!--end::Title--> */}
                                                {/* <!--begin::Notice--> */}
                                                <div className="text-muted fw-semibold fs-6">If you need more info, please contact us. </div>
                                                {/* <!--end::Notice--> */}
                                            </div>
                                            {/* <!--end::Heading--> */}
                                            {/* <!--begin::Input group--> */}
                                            <div className="mb-10 fv-row">
                                                {/* <!--begin::Label--> */}
                                                <label className="form-label mb-3 required">Exhibit name</label>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Input--> */}
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="exhibitName"
                                                    value={formState.exhibitName}
                                                    onChange={handleInputChange}
                                                    placeholder='Exhibit 21, John Doe`s Letter, My Diploma, etc...'
                                                    required
                                                />

                                                {/* <input type="text" className="form-control form-control-lg form-control-solid" name="account_name" placeholder="" value="" /> */}
                                                {/* <!--end::Input--> */}
                                            </div>
                                            {/* <!--end::Input group--> */}
                                            {/* <!--begin::Input group--> */}
                                            <div className="mb-0 fv-row">
                                                {/* <!--begin::Label--> */}
                                                <label className="d-flex align-items-center form-label mb-5 required">Exhibit Type
                                                    <span className="ms-1" data-bs-toggle="tooltip" title="Choose the type that best suits the exhibit you're creating.">
                                                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                        </i>
                                                    </span></label>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Input--> */}
                                                <select
                                                    name="exhibitType"
                                                    className="form-select form-select-lg form-select-solid"
                                                    value={formState.exhibitType}
                                                    onChange={handleInputChange}
                                                    data-control="select2"
                                                    data-placeholder="Select..."
                                                    data-allow-clear="true"
                                                    data-hide-search="true"
                                                    required
                                                >
                                                    <option value="">Select exhibit type...</option>
                                                    {/* <option value="CoverLetter">Cover Letter</option> */}
                                                    {/* <option value="Plan">Plan (Business, Professional, etc)</option> */}
                                                    <option value="Certificate">Certificate</option>
                                                    <option value="Letter">Letter (Recommendation, Accountant, etc)</option>
                                                    <option value="OtherExhibit">Other Exhibit</option>
                                                </select>
                                                {/* <!--end::Input--> */}
                                            </div>
                                            {/* <!--end::Input group--> */}
                                        </div>
                                        {/* <!--end::Wrapper--> */}
                                    </div>
                                    {/* <!--end::Step 2--> */}
                                </div>
                                {/* <!--end::Stepper--> */}
                            </div>
                            {/* <!--end::Modal body--> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <span className="btn btn-light-secondary" onClick={closeModalExhibit}>
                                Close
                            </span>
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* <!--begin::App--> */}
                <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                    {/* <!--begin::Page--> */}
                    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                        {/* <!--begin::Header--> */}
                        <Header />
                        {/* <!--end::Header--> */}
                        {/* <!--begin::Wrapper--> */}
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">

                            {/* <!--begin::Toolbar--> */}
                            <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                                {/* <!--begin::Toolbar container--> */}
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-column flex-row-fluid">
                                    {/* <!--begin::Breadcrumb and Tabs Container--> */}
                                    <div className="breadcrumb-tabs-container d-flex flex-column flex-lg-row">
                                        {/* <!--begin::Breadcrumb--> */}
                                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                <Link to={`/`}>
                                                    <span className="text-white text-hover-primary">
                                                        <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">{petition.petitionerName}</li>

                                        </ul>
                                        {/* <!--end::Breadcrumb--> */}

                                        {/* <!--begin::Tabs--> */}
                                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                                            <li className="nav-item">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'general' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('general')}
                                                >
                                                    General
                                                </span>
                                            </li>
                                            <li className="nav-item" id="navMenuForms">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'documents' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('documents')}
                                                >
                                                    Documents
                                                </span>
                                            </li>
                                            <li className="nav-item" id="navMenuForms">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'requests' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('requests')}
                                                >
                                                    Requests
                                                </span>
                                            </li>
                                            {type == "RFEResponse" && (
                                                <li className="nav-item" id="navMenuForms">
                                                    <span
                                                        className={`nav-link text-active-primary pb-4 ${activeTab === 'rfe' ? 'active' : 'cursor-pointer '}`}
                                                        onClick={() => handleTabClick('rfe')}
                                                    >
                                                        RFE
                                                    </span>
                                                </li>
                                            )}
                                            <li className="nav-item" id="navMenuForms">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'forms' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('forms')}
                                                >
                                                    Forms
                                                </span>
                                            </li>
                                            <li className="nav-item" id="navMenuCoverLetter">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'coverletter' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('coverletter')}
                                                >
                                                    Cover Letter
                                                    {((type == "OriginalSubmission" && !petition.hasCoverLetter) || (type == "RFEResponse" && !petition.hasCoverLetterRFE)) && (
                                                        <span className="badge badge-danger ms-2">!</span>
                                                    )}
                                                </span>
                                            </li>
                                            <li className="nav-item" id="navMenuPlan">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'plan' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('plan')}
                                                >
                                                    Plan
                                                    {((type == "OriginalSubmission" && !petition.hasPlan) || (type == "RFEResponse" && !petition.hasPlanRFE)) && (
                                                        <span className="badge badge-danger ms-2">!</span>
                                                    )}
                                                </span>
                                            </li>
                                            <li className="nav-item" id="navMenuExhibits">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'exhibits' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('exhibits')}
                                                >
                                                    Exhibits
                                                    <span className={`badge ${exhibits.length === 0 ? "badge-danger" : "badge-primary"} ms-2`}>{exhibits.length}</span>
                                                </span>
                                            </li>
                                            <li className="nav-item" id="navMenuForms">
                                                <span
                                                    className={`nav-link text-active-primary pb-4 ${activeTab === 'mypetition' ? 'active' : 'cursor-pointer '}`}
                                                    onClick={() => handleTabClick('mypetition')}
                                                >
                                                    My Petition
                                                </span>
                                            </li>
                                        </ul>
                                        {/* <!--end::Tabs--> */}
                                    </div>
                                    {/* <!--end::Breadcrumb and Tabs Container--> */}
                                </div>
                                {/* <!--end::Toolbar container--> */}
                            </div>
                            {/* <!--end::Toolbar--> */}
                            {/* <!--begin::Wrapper container--> */}
                            <div className="app-container container-xxl d-flex">
                                {/* <!--begin::Main--> */}
                                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                    {/* <!--begin::Content wrapper--> */}
                                    <div className="d-flex flex-column flex-column-fluid">
                                        {/* <!--begin::Content--> */}
                                        <div id="kt_app_content" className="app-content">
                                            {/*  {/* <!--begin::general --> */}
                                            {activeTab === 'general' && (
                                                <div className="row">
                                                    <div className='col-12 col-md-6 col-lg-3'>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="row">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-lg-6 mb-5">
                                                                    {/* <!--begin::Option--> */}
                                                                    <a href={`/petition/${petitionId}`} className={`btn ${type == "OriginalSubmission" ? "btn-primary" : "btn-active-light-primary"} btn-outline p-7 d-flex align-items-center`} htmlFor="kt_create_account_form_account_type_personal">

                                                                        {/* <!--begin::Info--> */}
                                                                        <span className="d-block fw-semibold text-start">
                                                                            <span className={`${type == "OriginalSubmission" ? "text-white" : "text-gray-900"} fw-bold d-block fs-4 mb-2`}>Original Submission</span>
                                                                            <span className="text-muted fw-semibold fs-6">It's my first petition.</span>
                                                                        </span>
                                                                        {/* <!--end::Info--> */}
                                                                    </a>
                                                                    {/* <!--end::Option--> */}
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-lg-6 mb-5">
                                                                    {/* <!--begin::Option--> */}
                                                                    <a href={`/petition/${petitionId}/rfe`} className={`btn ${type == "OriginalSubmission" ? "btn-active-light-secondary" : "btn-secondary"} btn-outline p-7 d-flex align-items-center`} htmlFor="kt_create_account_form_account_type_corporate">

                                                                        {/* <!--begin::Info--> */}
                                                                        <span className="d-block fw-semibold text-start">
                                                                            <span className={`${type == "OriginalSubmission" ? "text-gray-900" : "text-white"} fw-bold d-block fs-4 mb-2`}>RFE Response</span>
                                                                            <span className="text-muted fw-semibold fs-6">I'm answering a RFE.</span>
                                                                        </span>
                                                                        {/* <!--end::Info--> */}
                                                                    </a>
                                                                    {/* <!--end::Option--> */}
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                            </div>
                                                            <div className="row">
                                                                {/*  {/* <!--begin::User menu--> */}
                                                                <div className={`card mb-5 border rounded ${type == "OriginalSubmission" ? "border-primary" : "border-secondary"} `} id="cardPetitionDetails">
                                                                    {/*  {/* <!--begin::Body--> */}
                                                                    <div className="card-body pt-10 px-0">
                                                                        {/*  {/* <!--begin::Member--> */}
                                                                        <div className="d-flex flex-column text-center mb-3 px-9">
                                                                            {/* <!--begin::Stat--> */}
                                                                            {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 me-6 ms-6">
                                                                                <div className="align-items-center">
                                                                                    <div className="fs-1 fw-bold text-primary" >{petition.engagementPercentile}%</div>
                                                                                </div>
                                                                                <div className="fw-semibold fs-6 text-gray-500">Visa Genius Ranking
                                                                                    <span className="ms-1" data-bs-toggle="tooltip" title="A higher percentile shows your petition has more frequent and varied activities compared to most others in Visa Genius' database. For example, a percentile of 75% means your petition's engagement is higher than 75% of all petitions, emphasizing your strong participation and areas for further enhancement.">
                                                                                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                            <span className="path1"></span>
                                                                                            <span className="path2"></span>
                                                                                            <span className="path3"></span>
                                                                                        </i>
                                                                                    </span>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <!--end::Stat--> */}
                                                                            {/*  {/* <!--begin::Info--> */}
                                                                            <div className="text-center">
                                                                                {/*  {/* <!--begin::Name--> */}
                                                                                <span className="text-gray-800 fw-bold text-hover-primary fs-4">{petition.petitionerName}</span>
                                                                                {/*  {/* <!--end::Name--> */}
                                                                                {/*  {/* <!--begin::PetitionDetails--> */}
                                                                                {/* <span className="text-muted d-block fw-semibold">{petition.petitionType === "OriginalSubmission" ? "Original Submission" : "RFE"}</span> */}
                                                                                <span className="text-muted d-block fw-semibold">{petition.placeOfBirth}</span>
                                                                                {petition.receiptNumber && (
                                                                                    <span className="text-muted d-block fw-semibold text-gray-800">{petition.receiptNumber}</span>
                                                                                )}
                                                                                {/*  {/* <!--end::PetitionDetails--> */}
                                                                            </div>
                                                                            {/*  {/* <!--end::Info--> */}
                                                                        </div>
                                                                        {/*  {/* <!--end::Member--> */}
                                                                        {/*  {/* <!--begin::Navbar--> */}
                                                                        <div className="m-0">
                                                                            <div id="kt_job_1_1" className="collapse show fs-6 ms-1">
                                                                                <div className="text-center mb-2">
                                                                                    <span className={`badge py-2 px-3 fs-7 badge-light border border-info text-info`}>{petition.visaType}</span>
                                                                                </div>

                                                                                {petition && (!petition.visaType || petition.visaType == "EB2NIW") &&
                                                                                    <>
                                                                                        {/* <!--begin::Item--> */}
                                                                                        <div className="mb-4 fw-bold">
                                                                                            {/* <!--begin::Item--> */}
                                                                                            <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                {/* <!--begin::Label--> */}
                                                                                                {petition.subCategory === "AD" && (
                                                                                                    <>
                                                                                                        <i className="ki-outline ki-teacher fs-3 text-muted me-3"></i>Advanced Degree
                                                                                                        {(petitionAnalysis.passedAD) ?
                                                                                                            <>
                                                                                                                <i className="ki-outline ki-like fs-3 text-success ms-2"></i>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <i className="ki-outline ki-dislike fs-3 text-danger ms-2"></i>
                                                                                                            </>
                                                                                                        }

                                                                                                    </>
                                                                                                )}
                                                                                                {petition.subCategory === "EA" && (
                                                                                                    <>
                                                                                                        <i className="ki-outline ki-medal-star fs-3 text-muted me-3"></i>Exceptional Ability
                                                                                                        <i className={`ki-outline fs-3 ms-2 me-2 ${petitionAnalysis.passedEA ? "ki-like text-success" : "ki-dislike text-danger"}`}></i>
                                                                                                        <div className="fs-6 text-gray-500">{petitionAnalysis.reqEACount} of 6</div>

                                                                                                    </>
                                                                                                )}
                                                                                                {/* <!--end::Label--> */}
                                                                                            </div>
                                                                                            {/* <!--end::Item--> */}
                                                                                        </div>
                                                                                        {/* <!--end::Item--> */}
                                                                                        {/* <!--begin::Item--> */}
                                                                                        <div className="mb-4 fw-bold">
                                                                                            {/* <!--begin::Item--> */}
                                                                                            <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                {/* <!--begin::Label--> */}
                                                                                                <i className="ki-outline ki-wrench fs-3 text-muted me-3"></i>{petition.industry}
                                                                                                {/* <!--end::Label--> */}
                                                                                            </div>
                                                                                            {/* <!--end::Item--> */}
                                                                                        </div>
                                                                                        {/* <!--end::Item--> */}
                                                                                        {/* <!--begin::Item--> */}
                                                                                        <div className="mb-4 fw-bold">
                                                                                            {/* <!--begin::Item--> */}
                                                                                            <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                {/* <!--begin::Label--> */}
                                                                                                <i className="ki-outline ki-pin fs-3 text-muted me-3"></i>{petition.stateOfEndeavor}
                                                                                                {/* <!--end::Label--> */}
                                                                                            </div>
                                                                                            {/* <!--end::Item--> */}
                                                                                        </div>
                                                                                        {/* <!--end::Item--> */}
                                                                                    </>
                                                                                }
                                                                                {/*  {/* <!--begin::Info--> */}
                                                                                <div className="text-center">
                                                                                    {/*  {/* <!--begin::Name--> */}
                                                                                    <Link to={`/petition/${petitionId}/settings`}>
                                                                                        <span className="fw-bold text-hover-primary fs-5">Settings</span>
                                                                                    </Link>
                                                                                    {/* <Link to={`/petition/${petitionId}/magicform`}>
                                                                                        <span className="fw-bold text-hover-primary fs-5">Magic Form</span>
                                                                                    </Link> */}
                                                                                    {/*  {/* <!--end::Name--> */}
                                                                                </div>
                                                                                {/*  {/* <!--end::Info--> */}
                                                                            </div>
                                                                        </div>
                                                                        {/*  {/* <!--end::Navbar--> */}
                                                                    </div>
                                                                    {/*  {/* <!--end::Body--> */}
                                                                </div>
                                                                {/*  {/* <!--end::User menu--> */}
                                                            </div>
                                                            <div className="row">
                                                                {petition.receiptNumber ? (
                                                                    <div className="card card-flush border-0 mb-5 ">
                                                                        {/*  {/* <!--begin::Header--> */}
                                                                        <div className="card-header pt-7">
                                                                            {/*  {/* <!--begin::Title--> */}
                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                <span className="card-label fw-bold text-primary">Case Timeline</span>
                                                                                {(petition.lastStatusCaseTimestamp &&
                                                                                    <span className="text-gray-500 mt-1 fw-semibold fs-6">{daysFromTimestamp(petition.lastStatusCaseTimestamp)} days since last activity</span>
                                                                                )}
                                                                            </h3>
                                                                            {/*  {/* <!--end::Title--> */}
                                                                        </div>
                                                                        {/*  {/* <!--end::Header--> */}
                                                                        {/*  {/* <!--begin::Body--> */}
                                                                        <div className="card-body">
                                                                            {/*  {/* <!--begin::Tab Content--> */}
                                                                            <div className="tab-content">
                                                                                {/*  {/* <!--begin::Tap pane--> */}
                                                                                <div className="tab-pane fade show active">
                                                                                    {/*  {/* <!--begin::Item--> */}
                                                                                    <div className="m-0">
                                                                                        {/*  {/* <!--begin::Timeline--> */}
                                                                                        <div className="timeline">

                                                                                            {petition.Timeline?.map((timelineitem, index) => (
                                                                                                <div key={timelineitem.id}>
                                                                                                    {index === 0 ? (
                                                                                                        <>
                                                                                                            <div className="timeline-item align-items-center mb-7" >
                                                                                                                {/* <div key={timelineitem.id} className="timeline-item align-items-center mb-7" > */}
                                                                                                                {/*  {/* <!--begin::Timeline line--> */}
                                                                                                                <div className="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
                                                                                                                {/*  {/* <!--end::Timeline line--> */}
                                                                                                                {/*  {/* <!--begin::Timeline icon--> */}
                                                                                                                <div className="timeline-icon timeline-icon bg-primary">
                                                                                                                    <i className="ki-outline ki-geolocation fs-2 text-inverse-primary"></i>
                                                                                                                </div>
                                                                                                                {/*  {/* <!--end::Timeline icon--> */}
                                                                                                                {/*  {/* <!--begin::Timeline content--> */}
                                                                                                                <div className="timeline-content m-0">
                                                                                                                    {/*  {/* <!--begin::Title--> */}
                                                                                                                    <span className="fs-6 text-primary d-block fw-bold">{timelineitem.description}</span>
                                                                                                                    {/*  {/* <!--end::Title--> */}
                                                                                                                    {/*  {/* <!--begin::Title--> */}
                                                                                                                    <span className="fs-6 fw-bold text-gray-700">{timelineitem.date}</span>
                                                                                                                    {/*  {/* <!--end::Title--> */}
                                                                                                                </div>
                                                                                                                {/*  {/* <!--end::Timeline content--> */}
                                                                                                                {/* </div> */}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <div className="timeline-item align-items-center mb-7" >
                                                                                                                {/* <div key={timelineitem.id} className="timeline-item align-items-center mb-7" > */}
                                                                                                                {/*  {/* <!--begin::Timeline line--> */}
                                                                                                                <div className="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
                                                                                                                {/*  {/* <!--end::Timeline line--> */}
                                                                                                                {/*  {/* <!--begin::Timeline icon--> */}
                                                                                                                <div className="timeline-icon">
                                                                                                                    <i className="ki-outline ki-cd fs-2 text-danger"></i>
                                                                                                                </div>
                                                                                                                {/*  {/* <!--end::Timeline icon--> */}
                                                                                                                {/*  {/* <!--begin::Timeline content--> */}
                                                                                                                <div className="timeline-content m-0">
                                                                                                                    {/*  {/* <!--begin::Title--> */}
                                                                                                                    <span className="fs-6 text-gray-700 fw-semibold d-block">{timelineitem.description}</span>
                                                                                                                    {/*  {/* <!--end::Title--> */}
                                                                                                                    {/*  {/* <!--begin::Title--> */}
                                                                                                                    <span className="fs-6 fw-bold text-gray-500">{timelineitem.date}</span>
                                                                                                                    {/*  {/* <!--end::Title--> */}
                                                                                                                </div>
                                                                                                                {/*  {/* <!--end::Timeline content--> */}
                                                                                                                {/* </div> */}
                                                                                                            </div>

                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </div >
                                                                                        {/*  {/* <!--end::Timeline--> */}
                                                                                    </div>
                                                                                    {/*  {/* <!--end::Item--> */}
                                                                                </div>
                                                                                {/*  {/* <!--end::Tap pane--> */}
                                                                            </div>
                                                                            {/*  {/* <!--end::Tab Content--> */}
                                                                        </div>
                                                                        {/*  {/* <!--end: Card Body--> */}
                                                                    </div>
                                                                ) : (
                                                                    <div className="card border-0 mb-5 bg-primary" data-bs-theme="light" id="cardReceiptNumber">
                                                                        {/*  {/* <!--begin::Body--> */}
                                                                        <div className="card-body py-0">
                                                                            {/*  {/* <!--begin::Row--> */}
                                                                            <div className="row align-items-center lh-1 h-100">
                                                                                {/*  {/* <!--begin::Col--> */}
                                                                                <div className="col-12 mb-5 mt-5">
                                                                                    {/*  {/* <!--begin::Title--> */}
                                                                                    <div className="fs-2qx fw-bold text-white mb-6">Enter Your Case Receipt Number Now!</div>
                                                                                    {/*  {/* <!--end::Title--> */}
                                                                                    {/*  {/* <!--begin::Text--> */}
                                                                                    <span className="fw-semibold text-white fs-6 mb-10 d-block opacity-75">Input your case receipt number to receive real-time notifications, detailed status updates, and personalized statistics.</span>
                                                                                    {/*  {/* <!--end::Text--> */}
                                                                                    {/*  {/* <!--begin::Action--> */}
                                                                                    <form onSubmit={handleSubmitReceiptNumber}>
                                                                                        <div className="d-flex d-grid gap-2">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control form-control-lg form-control-solid"
                                                                                                name="receiptNumber"
                                                                                                value={formStateReceiptNumber.receiptNumber}
                                                                                                onChange={handleInputChangeReceiptNumber}
                                                                                                placeholder='ABC1234567890'
                                                                                                required
                                                                                            />
                                                                                            <button type="submit" className="btn btn-success">Go!</button>
                                                                                        </div>
                                                                                    </form>
                                                                                    {/*  {/* <!--end::Action--> */}
                                                                                </div>
                                                                                {/*  {/* <!--end::Col--> */}
                                                                            </div>
                                                                            {/*  {/* <!--end::Row--> */}
                                                                        </div>
                                                                        {/*  {/* <!--end::Body--> */}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 col-lg-9'>
                                                        <div className='mb-5'>
                                                            {/* TaskManager Component */}
                                                            <TaskManager petition={petition} type={type} isReadOnly={isReadOnly} />
                                                        </div>
                                                        {petition && (!petition.visaType || petition.visaType == "EB2NIW") &&
                                                            <div className="row" id="rowEvidenceDistribution">
                                                                <div className="col-12 col-md-12 col-lg-4">
                                                                    {/*  {/* <!--begin::Prong1 menu--> */}
                                                                    <div className="card mb-5 ">
                                                                        {/* <!--begin::Header--> */}
                                                                        <div className="card-header pt-7">
                                                                            {/* <!--begin::Title--> */}
                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                <span className="card-label fs-3 fw-bold text-primary">Prong 1</span>
                                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence Distribution
                                                                                    <span className="ms-1" data-bs-toggle="tooltip" title="The Evidence Distribution reflects the proportion of specific activities within your petition, showing which areas are most actively pursued and documented.">
                                                                                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                            <span className="path1"></span>
                                                                                            <span className="path2"></span>
                                                                                            <span className="path3"></span>
                                                                                        </i>
                                                                                    </span>
                                                                                </span>
                                                                            </h3>
                                                                            {/* <!--end::Title--> */}
                                                                            {/*  {/* <!--begin::Actions--> */}
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                {/*  {/* <!--begin::Secondary button--> */}
                                                                                <div className="m-0">
                                                                                    {/*  {/* <!--begin::Menu--> */}
                                                                                    <span className="text-muted">{checkedItems.descriptionEndeavor + checkedItems.documentaryEvidence}%</span>
                                                                                    {/*  {/* <!--end::Menu--> */}
                                                                                </div>
                                                                                {/*  {/* <!--end::Secondary button--> */}
                                                                            </div>
                                                                            {/*  {/* <!--end::Actions--> */}
                                                                        </div>
                                                                        {/* <!--end::Header--> */}
                                                                        {/*  {/* <!--begin::Body--> */}
                                                                        <div className="card-body">
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Description of Endeavor</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.descriptionEndeavor}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-danger rounded h-10px" role="progressbar" style={{ width: checkedItems.descriptionEndeavor + '%' }} aria-valuenow={checkedItems.descriptionEndeavor} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Documentary Evidence</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.documentaryEvidence}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-danger rounded h-10px" role="progressbar" style={{ width: checkedItems.documentaryEvidence + '%' }} aria-valuenow={checkedItems.documentaryEvidence} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <Chart options={prong1RadialBarOptions.options} series={prong1RadialBarOptions.series} type="radialBar" height={350} />
                                                                                <span className="fw-bold fs-6 text-gray-700">Prong 1 Coverage</span>
                                                                            </div>
                                                                        </div>
                                                                        {/*  {/* <!--end::Body--> */}
                                                                    </div>
                                                                    {/*  {/* <!--end::Prong1 menu--> */}
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-4">
                                                                    {/*  {/* <!--begin::Prong1 menu--> */}
                                                                    <div className="card mb-5 ">
                                                                        {/* <!--begin::Header--> */}
                                                                        <div className="card-header pt-7">
                                                                            {/* <!--begin::Title--> */}
                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                <span className="card-label fs-3 fw-bold text-primary">Prong 2</span>
                                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence Distribution
                                                                                    <span className="ms-1" data-bs-toggle="tooltip" title="The Evidence Distribution reflects the proportion of specific activities within your petition, showing which areas are most actively pursued and documented.">
                                                                                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                            <span className="path1"></span>
                                                                                            <span className="path2"></span>
                                                                                            <span className="path3"></span>
                                                                                        </i>
                                                                                    </span>
                                                                                </span>
                                                                            </h3>
                                                                            {/* <!--end::Title--> */}
                                                                            {/*  {/* <!--begin::Actions--> */}
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                {/*  {/* <!--begin::Secondary button--> */}
                                                                                <div className="m-0">
                                                                                    {/*  {/* <!--begin::Menu--> */}
                                                                                    <span className="text-muted">{checkedItems.qualifications + checkedItems.futureActivityPlans + checkedItems.progressTowardsEndeavor + checkedItems.interestOfStakeholders}%</span>
                                                                                    {/*  {/* <!--end::Menu--> */}
                                                                                </div>
                                                                                {/*  {/* <!--end::Secondary button--> */}
                                                                            </div>
                                                                            {/*  {/* <!--end::Actions--> */}
                                                                        </div>
                                                                        {/* <!--end::Header--> */}
                                                                        {/*  {/* <!--begin::Body--> */}
                                                                        <div className="card-body">
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Education, Skills, and Success Record</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.qualifications}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-warning rounded h-10px" role="progressbar" style={{ width: checkedItems.qualifications + '%' }} aria-valuenow={checkedItems.qualifications} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Future Activity Plans</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.futureActivityPlans}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-warning rounded h-10px" role="progressbar" style={{ width: checkedItems.futureActivityPlans + '%' }} aria-valuenow={checkedItems.futureActivityPlans} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Progress Towards Endeavor</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.progressTowardsEndeavor}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-warning rounded h-10px" role="progressbar" style={{ width: checkedItems.progressTowardsEndeavor + '%' }} aria-valuenow={checkedItems.progressTowardsEndeavor} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Interest of Stakeholders</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.interestOfStakeholders}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-warning rounded h-10px" role="progressbar" style={{ width: checkedItems.interestOfStakeholders + '%' }} aria-valuenow={checkedItems.interestOfStakeholders} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="text-center">
                                                                                <Chart options={prong2RadialBarOptions.options} series={prong2RadialBarOptions.series} type="radialBar" height={350} />
                                                                                <span className="fw-bold fs-6 text-gray-700">Prong 2 Coverage</span>
                                                                            </div>
                                                                        </div>
                                                                        {/*  {/* <!--end::Body--> */}
                                                                    </div>
                                                                    {/*  {/* <!--end::Prong1 menu--> */}
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-4">
                                                                    {/*  {/* <!--begin::Prong1 menu--> */}
                                                                    <div className="card mb-5 ">
                                                                        {/* <!--begin::Header--> */}
                                                                        <div className="card-header pt-7">
                                                                            {/* <!--begin::Title--> */}
                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                <span className="card-label fs-3 fw-bold text-primary">Prong 3</span>
                                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence Distribution
                                                                                    <span className="ms-1" data-bs-toggle="tooltip" title="The Evidence Distribution reflects the proportion of specific activities within your petition, showing which areas are most actively pursued and documented.">
                                                                                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                            <span className="path1"></span>
                                                                                            <span className="path2"></span>
                                                                                            <span className="path3"></span>
                                                                                        </i>
                                                                                    </span>
                                                                                </span>
                                                                            </h3>
                                                                            {/* <!--end::Title--> */}
                                                                            {/*  {/* <!--begin::Actions--> */}
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                {/*  {/* <!--begin::Secondary button--> */}
                                                                                <div className="m-0">
                                                                                    {/*  {/* <!--begin::Menu--> */}
                                                                                    <span className="text-muted">{checkedItems.laborCertificationImpracticality + checkedItems.benefitToUSDespiteWorkers + checkedItems.urgencyAndJobCreation}%</span>
                                                                                    {/*  {/* <!--end::Menu--> */}
                                                                                </div>
                                                                                {/*  {/* <!--end::Secondary button--> */}
                                                                            </div>
                                                                            {/*  {/* <!--end::Actions--> */}
                                                                        </div>
                                                                        {/* <!--end::Header--> */}
                                                                        {/*  {/* <!--begin::Body--> */}
                                                                        <div className="card-body">
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Labor Certification Impracticality</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.laborCertificationImpracticality}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-info rounded h-10px" role="progressbar" style={{ width: checkedItems.laborCertificationImpracticality + '%' }} aria-valuenow={checkedItems.laborCertificationImpracticality} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Benefit to the U.S. Despite Availability of Workers</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.benefitToUSDespiteWorkers}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-info rounded h-10px" role="progressbar" style={{ width: checkedItems.benefitToUSDespiteWorkers + '%' }} aria-valuenow={checkedItems.benefitToUSDespiteWorkers} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center flex-column">
                                                                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                                    <span className="fw-bold fs-6 text-gray-700">Urgency and Job Creation</span>
                                                                                    <span className="fw-semibold fs-7 text-gray-500">{checkedItems.urgencyAndJobCreation}%</span>
                                                                                </div>
                                                                                <div className="h-10px mx-3 w-100 bg-light mb-3">
                                                                                    <div className="bg-info rounded h-10px" role="progressbar" style={{ width: checkedItems.urgencyAndJobCreation + '%' }} aria-valuenow={checkedItems.urgencyAndJobCreation} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <Chart options={prong3RadialBarOptions.options} series={prong3RadialBarOptions.series} type="radialBar" height={350} />
                                                                                <span className="fw-bold fs-6 text-gray-700">Prong 3 Coverage</span>
                                                                            </div>

                                                                        </div>
                                                                        {/*  {/* <!--end::Body--> */}
                                                                    </div>
                                                                    {/*  {/* <!--end::Prong1 menu--> */}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            {/*  {/* <!--end::general --> */}
                                            {/* <!--begin:Requests --> */}
                                            {activeTab === 'requests' && (
                                                <RequestManager petition={petition} type={type} />
                                            )}
                                            {/* <!--end:Requests --> */}

                                            {/* <!--begin:Documents --> */}
                                            {activeTab === 'documents' && (
                                                <DocumentsManager petition={petition} type={type} />
                                            )}
                                            {/* <!--end:Requests --> */}

                                            {/*  {/* <!--begin::rfe --> */}
                                            {activeTab === 'rfe' && (
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <PetitionRFE petitionId={petitionId} petitionFolderId={petition.folderId} petitionerName={petition.petitionerName} isReadOnly={isReadOnly} />
                                                    </div>
                                                </div>
                                            )}
                                            {/*  {/* <!--end::rfe --> */}
                                            {/*  {/* <!--begin::forms --> */}
                                            {activeTab === 'forms' && (
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <PetitionForms petitionId={petitionId} petitionFolderId={type == "OriginalSubmission" ? petition.folderId : petition.folderRFEId} type={type} isReadOnly={isReadOnly} />
                                                    </div>
                                                </div>
                                            )}
                                            {/*  {/* <!--end::forms --> */}
                                            {/* <!--begin:cover letter --> */}
                                            {activeTab === 'coverletter' && (
                                                <PetitionCoverLetter petitionId={petitionId} petitionFolderId={petition.folderId} updatePetition={updatePetition} type={type} isReadOnly={isReadOnly} />
                                            )}
                                            {/* <!--end:cover letter --> */}
                                            {/* <!--begin:plan --> */}
                                            {activeTab === 'plan' && (
                                                <PetitionPlan petitionId={petitionId} petitionFolderId={petition.folderId} updatePetition={updatePetition} type={type} isReadOnly={isReadOnly} />
                                            )}
                                            {/* <!--end:plan --> */}
                                            {/* <!--begin:exhibits --> */}
                                            {activeTab === 'exhibits' && (
                                                <div className="d-flex flex-column flex-lg-row">
                                                    {/*  {/* <!--begin::Sidebar--> */}
                                                    <div className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px" data-kt-drawer="true" data-kt-drawer-name="inbox-aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_inbox_aside_toggle">
                                                        {/*  {/* <!--begin::Sticky aside--> */}
                                                        <div className="card card-flush mb-0" data-kt-sticky="false" data-kt-sticky-name="inbox-aside-sticky" data-kt-sticky-offset="{default: false, xl: '100px'}" data-kt-sticky-width="{lg: '275px'}" data-kt-sticky-left="auto" data-kt-sticky-top="100px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">
                                                            {/*  {/* <!--begin::Aside content--> */}
                                                            <div className="card-body">
                                                                {/*  {/* <!--begin::Button--> */}
                                                                {/* <a href="apps/inbox/compose.html" className="btn btn-primary fw-bold w-100 mb-8">New Message</a> */}
                                                                {/*  {/* <!--end::Button--> */}
                                                                {/*  {/* <!--begin::Menu--> */}
                                                                <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary">
                                                                    {/*  {/* <!--begin::Menu item--> */}
                                                                    {exhibitTypes.map(({ name, icon, desc, style }) => (
                                                                        <div key={name} className="menu-item mb-3">
                                                                            <span
                                                                                className={`menu-link ${activeItem === name ? 'active' : ''}`}
                                                                                onClick={() => handleMenuItemClick(name)}
                                                                            >
                                                                                <span className="menu-icon">
                                                                                    <i className={`ki-outline ${icon} fs-2 me-3`}></i>
                                                                                </span>
                                                                                <span className="menu-title fw-bold">{desc}</span>
                                                                                {getExhibitCount(name) > 0 && (
                                                                                    <span className={`badge ${style}`}>
                                                                                        {getExhibitCount(name)}
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                                    {/*  {/* <!--end::Menu item--> */}
                                                                </div>
                                                                {/*  {/* <!--end::Menu--> */}
                                                            </div>
                                                            {/*  {/* <!--end::Aside content--> */}
                                                        </div>
                                                        {/*  {/* <!--end::Sticky aside--> */}
                                                    </div>
                                                    {/*  {/* <!--end::Sidebar--> */}
                                                    {/*  {/* <!--begin::Content--> */}
                                                    <div className="flex-lg-row-fluid ms-lg-7">
                                                        {/* <!--begin::Card Widget 22--> */}
                                                        <div className="card card-flush border-0">
                                                            {/* <!--begin::Header--> */}
                                                            <div className="card-header pt-7">
                                                                {/* <!--begin::Title--> */}
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fs-3 fw-bold text-primary">Exhibits</span>
                                                                    <span className="text-gray-500 mt-1 fw-semibold fs-6">All submited exhibits (letters, plan, certificates and etc.) </span>
                                                                </h3>
                                                                {/* <!--end::Title--> */}
                                                                {/* <!--begin::Toolbar--> */}
                                                                <div className="card-toolbar">
                                                                    {!isReadOnly ? (
                                                                        <span onClick={openModalExhibit} className="btn btn-primary"><i className="ki-outline ki-plus fs-2"></i>New Exhibit</span>
                                                                    ) : (<></>)}

                                                                </div>
                                                                {/* <!--end::Toolbar--> */}
                                                            </div>
                                                            {/* <!--end::Header--> */}
                                                            {/* <!--begin::Body--> */}
                                                            <div className="card-body pt-6">
                                                                {/* <!--begin::Table container--> */}
                                                                <PetitionExhibits
                                                                    exhibits={filteredExhibits}
                                                                    deleteExhibitCall={deleteExhibitCall}
                                                                    renameExhibitCall={renameExhibitCall}
                                                                    getBadgeClass={getBadgeClass}
                                                                    petitionId={petitionId}
                                                                    visaType={petition.visaType}
                                                                    type={type}
                                                                    isReadOnly={isReadOnly}
                                                                />

                                                                {/* <!--end::Table--> */}
                                                                {/* <!--begin::Separator--> */}
                                                                {/* <div className="separator separator-dashed border-gray-200 mb-n4"></div> */}
                                                                {/* <!--end::Separator--> */}
                                                            </div>
                                                            {/* <!--end: Card Body--> */}
                                                        </div>
                                                        {/* <!--end::Card Widget 22--> */}
                                                    </div>
                                                    {/*  {/* <!--end::Content--> */}
                                                </div>
                                            )}
                                            {/*  {/* <!--end::Exhibits --> */}
                                            {/* <!--begin:Print --> */}
                                            {activeTab === 'mypetition' && (
                                                <PetitionMyPetition petitionId={petitionId} hasCoverLetter={type == "OriginalSubmission" ? petition.hasCoverLetter : petition.hasCoverLetterRFE} hasPlan={type == "OriginalSubmission" ? petition.hasPlan : petition.hasPlanRFE} type={type} petitionerName={petition.petitionerName} />
                                            )}
                                            {/* <!--end:Print --> */}
                                        </div>
                                        {/* <!--end::Content--> */}
                                    </div>
                                    {/* <!--end::Content wrapper--> */}
                                    {/* <!--begin::Footer--> */}
                                    <Footer />
                                    {/* <!--end::Footer--> */}
                                </div>
                                {/* <!--end:::Main--> */}
                            </div>
                            {/* <!--end::Wrapper container--> */}

                        </div>
                        {/* <!--end::Wrapper--> */}
                    </div>
                    {/* <!--end::Page--> */}
                </div >
            </div >
        </>
    );
}

export default PetitionDetail;
