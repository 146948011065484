// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import { ExhibitProvider } from '../contexts/ExhibitContext';
import AppRoutes from './AppRoutes';
import { SubscriptionProvider } from '../contexts/SubscriptionContext';

function App() {
  return (
    <AuthProvider>
      <ExhibitProvider>
        <SubscriptionProvider>
          <Router>
            <AppRoutes />
          </Router>
        </SubscriptionProvider>
      </ExhibitProvider>
    </AuthProvider>
  );
}

export default App;