import React, { useState } from 'react';
import Swal from 'sweetalert2'
import './SubscriptionModal.css';
import { getDaysLeftInTrial } from '../../utils/Utils.js'
import Loading from '../../components/Loading/Loading';
import { useAuth } from '../../contexts/AuthContext.js';
import { Modal } from 'react-bootstrap';
import { createCheckoutSession, switchPlan } from '../../services/ApiService'; // Import necessary API functions

const SubscriptionModal = ({ type, onClose, forceOpen = false }) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const isSubscriptionRequired = type === 'subscription-required';

    const trialDaysLeft = getDaysLeftInTrial(currentUser?.EndTrialDate);

    const featuresList = [
        'Petitions',
        'USCIS Status Tracking',
        'Exhibit Prong Stats',
        'Access Community',
        'Share Petition (Paid seats)',
        'Free client access (read-only)',
        'Tasks (TO-DO list)',
        'Requests (To shared users)',
        'Print Petition (PDF)',
        'AI Chat (Ask Genius)',
        'Cover Letter AI Analyzer'
    ];

    const plans = [
        {
            name: 'Self Petitioner',
            codename: 'SELF',
            price: 9,
            description: 'Perfect for individuals creating their own petitions without professional assistance.',
            featuresAvailable: ['1', true, true, true, true, false, false, false, false, 'Limited', 'Limited']
        },
        {
            name: 'Expert',
            codename: 'EXPERT',
            price: 49,
            description: 'Designed for consultants, lawyers, and immigration offices managing multiple clients.',
            featuresAvailable: ['Unlimited', true, true, true, true, true, true, true, true, 'Unlimited', 'Unlimited']
        }
    ];

    const handlePlanSelect = async (planType) => {
        try {
            const plan = planType === 'self petitioner' ? 'SELF' : 'EXPERT';

            const currentStatus = currentUser?.SubscriptionStatus?.toLowerCase();
            const currentPlan = currentUser?.Plan?.toUpperCase();
            const isChangingPlan = currentStatus === 'active' || currentStatus === 'trialing';

            if (isChangingPlan) {
                if (currentPlan === plan) {
                    await Swal.fire({
                        title: 'Info',
                        text: 'You are already on this plan.',
                        icon: 'info',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    });
                    return;
                }

                setLoading(true);
                const { data } = await switchPlan(planType);
                setLoading(false);

                await Swal.fire({
                    title: 'Plan changed successfully!',
                    text: data?.message || 'Your plan has been updated.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    },
                    buttonsStyling: false
                });

                window.location.reload(); // ✅ Refresh the page after plan change
            } else {
                setLoading(true);
                const { sessionUrl } = await createCheckoutSession(plan);
                setLoading(false);

                if (sessionUrl) {
                    window.location.href = sessionUrl;
                } else {
                    await Swal.fire({
                        title: 'Oops!',
                        text: 'Could not retrieve checkout session URL.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });
                }
            }
        } catch (error) {
            console.error('Error during plan selection:', error);
            await Swal.fire({
                title: 'Error',
                text: 'Something went wrong while selecting your plan. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Loading isLoading={loading} />
            <Modal show={true} onHide={!forceOpen ? onClose : undefined} backdrop={forceOpen ? 'static' : true} keyboard={!forceOpen} size="xl">
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bold mb-5">Choose Your Plan</h1>
                        </div>

                        {/* Responsive: show as stacked cards on small screens */}
                        <div className="d-block d-lg-none">
                            {plans.map((plan, pIndex) => {
                                const isCurrent = !isSubscriptionRequired && currentUser?.Plan?.toLowerCase() === plan.codename.toLowerCase();

                                return (
                                    <div className="card mb-6 p-5 shadow-sm position-relative" key={pIndex}>
                                        {/* Badge image for both plans */}
                                        {(currentUser?.SubscriptionStatus == "inactive" || currentUser?.SubscriptionStatus == "trialing") && (
                                            <img src="/assets/images/7dayfreetrial.png" alt="Free Trial" className="free-trial-badge-mobile" />
                                        )}
                                        <h3 className="fw-bold text-dark mb-2">{plan.name}
                                            {!isSubscriptionRequired && currentUser.currentUser?.Plan?.toLowerCase() === plan.codename.toLowerCase() && (
                                                <div className="badge badge-success fw-bold top-0 end-0 m-3">Current Plan</div>
                                            )}
                                        </h3>
                                        <p className="text-gray-600 mt-7 mb-3">{plan.description}</p>
                                        <div className="text-primary fs-2 fw-bolder mb-4">${plan.price}/user/mo3</div>
                                        <ul className="list-group mb-4">
                                            {featuresList.map((feature, fIndex) => (
                                                <li className="list-group-item d-flex justify-content-between align-items-center" key={fIndex}>
                                                    <span>{feature}</span>
                                                    {plan.featuresAvailable[fIndex] === true && <i className="ki-outline ki-check-circle fs-2 text-success"></i>}
                                                    {plan.featuresAvailable[fIndex] === false && <i className="ki-outline ki-cross-circle fs-2 text-danger"></i>}
                                                    {typeof plan.featuresAvailable[fIndex] === 'string' && <span className="badge badge-light-primary fw-bold">{plan.featuresAvailable[fIndex]}</span>}
                                                </li>
                                            ))}
                                        </ul>

                                        <button
                                            className="btn btn-primary w-100"
                                            onClick={() => !isCurrent && handlePlanSelect(plan.name.toLowerCase())}
                                            disabled={isCurrent}
                                        >
                                            {isCurrent ? 'Current Plan' : `Select ${plan.name}`}
                                        </button>
                                        {/* <button className="btn btn-primary w-100" onClick={() => handlePlanSelect(plan.name.toLowerCase())}>Select {plan.name}</button> */}
                                    </div>
                                );
                            })}
                        </div>

                        {/* Desktop: show table */}
                        <div className="table-responsive mb-10 d-none d-lg-block">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th className="text-start">Features</th>
                                        {plans.map((plan, i) => (
                                            <th key={i} style={{ minWidth: '240px', position: 'relative' }}>
                                                {(currentUser?.SubscriptionStatus == "inactive" || currentUser?.SubscriptionStatus == "trialing") && (
                                                    <img src="/assets/images/7dayfreetrial.png" alt="Free Trial" className="free-trial-badge" />
                                                )}
                                                <div className="fs-2 fw-bold text-dark">{plan.name}
                                                    {!isSubscriptionRequired && currentUser?.Plan?.toLowerCase() === plan.codename.toLowerCase() && (
                                                        <div className="badge badge-success fw-bold top-0 end-0 m-3">Current Plan</div>
                                                    )}
                                                </div>
                                                <div className="text-gray-600 mt-7 fs-6 mb-2">{plan.description}</div>
                                                <div className="text-primary fs-2 fw-bolder">${plan.price}/user/mo</div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {featuresList.map((feature, index) => (
                                        <tr key={index}>
                                            <td className="text-start fw-semibold fs-6 text-break align-middle" style={{ minWidth: 280 }}>{feature}</td>
                                            {plans.map((plan, i) => (
                                                <td key={i} className="align-middle text-break">
                                                    {plan.featuresAvailable[index] === true && <i className="ki-outline ki-check-circle fs-2 text-success"></i>}
                                                    {plan.featuresAvailable[index] === false && <i className="ki-outline ki-cross-circle fs-2 text-danger"></i>}
                                                    {typeof plan.featuresAvailable[index] === 'string' && <span className="badge badge-light-primary fw-bold">{plan.featuresAvailable[index]}</span>}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    <tr>
                                        <td></td>
                                        {plans.map((plan, i) => {
                                            const isCurrent = !isSubscriptionRequired && currentUser?.Plan?.toLowerCase() === plan.codename.toLowerCase();
                                            return (
                                                <td key={i}>
                                                    <button
                                                        className={`btn btn-lg btn-primary`}
                                                        onClick={() => !isCurrent && handlePlanSelect(plan.name.toLowerCase())}
                                                        disabled={isCurrent}
                                                    >
                                                        {isCurrent ? 'Current Plan' : `Select ${plan.name}`}
                                                    </button>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {currentUser?.SubscriptionStatus == "trialing" && (
                            <div className="alert alert-info text-center mb-4 fs-3">
                                You're on a free trial. {trialDaysLeft} day{trialDaysLeft !== 1 && 's'} left!
                            </div>
                        )}

                        {(currentUser?.SubscriptionStatus != "trialing" && currentUser?.SubscriptionStatus != "active") && (
                            <p className="text-center text-danger fw-semibold">
                                You must choose a plan to continue using the service
                            </p>
                        )}

                        {(currentUser?.SubscriptionStatus == "trialing" || currentUser?.SubscriptionStatus == "active") && (
                            <div className="text-center">
                                <button className="btn btn-light" onClick={onClose}>Close</button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default SubscriptionModal;