// RequestManager.js
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Modal, Form, Row, Col } from 'react-bootstrap';
import { WithContext as ReactTagInput } from 'react-tag-input';
import Loading from '../Loading/Loading';
import { formatDate, isOverdue, generateColorFromName, customStyles } from '../../utils/Utils.js'
import { useAuth } from '../../contexts/AuthContext.js';
import {
    fetchRequests,
    createRequest,
    deleteRequest,
    changeRequestStatus,
    fetchSharedWithUsers
} from '../../services/ApiService';
import { useSubscription } from '../../contexts/SubscriptionContext';

const RequestManager = ({ petition, type }) => {
    const [petitionId, setPetitionId] = useState(petition.id);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [selectedRequestsPriority, setSelectedRequestsPriority] = useState('Show All');
    const [selectedRequestsStatus, setSelectedRequestsStatus] = useState('Show All');
    const [requests, setRequests] = useState([]);
    const [request, setRequest] = useState({
        subject: '',
        content: '',
        priority: 'Low',
        status: 'Pending',
        assigneeUserId: '',           // New field for assignee
        requestType: 'document request', // Default value for request type
        duedate: '',            // New field for duedate
    });
    const { openSubscriptionModal } = useSubscription();

    const [hashtags, setHashtags] = useState([]); // Store hashtags as an array

    const [loading, setLoading] = useState(false);
    const [sharedWithUsers, setSharedWithUsers] = useState([]);
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchUsers = async () => {
            const users = await fetchSharedWithUsers(petitionId);
            const filteredUsers = users.filter(user => user.id !== currentUser.uid);
            setSharedWithUsers(filteredUsers);

            // If there's exactly one user, pre-select them as the assignee
            if (filteredUsers.length === 1) {
                setRequest((prevRequest) => ({
                    ...prevRequest,
                    assigneeUserId: filteredUsers[0].id,  // Automatically select the only user
                }));
            }
        };

        fetchUsers();
    }, [petitionId]);

    // Function to handle adding and removing hashtags
    const handleAddHashtag = (tag) => {
        setHashtags([...hashtags, tag]);
    };

    const handleDeleteHashtag = (i) => {
        setHashtags(hashtags.filter((tag, index) => index !== i));
    };

    // Load requests from the API
    const loadRequests = async () => {
        try {
            const fetchedRequests = await fetchRequests(petitionId, type);
            setRequests(fetchedRequests);
        } catch (error) {
            console.error('Failed to load requests:', error);
        }
    };

    useEffect(() => {
        loadRequests();
    }, [petitionId, type]);

    const handleShowRequestModal = () => setShowRequestModal(true);
    const handleCloseRequestModal = () => setShowRequestModal(false);

    const handleChangeRequestModal = (e) => {
        const { name, value } = e.target;
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
    };

    const handleRequestsFilterChange = (e, type) => {
        if (type === 'priority') {
            setSelectedRequestsPriority(e.target.value);
        } else if (type === 'status') {
            setSelectedRequestsStatus(e.target.value);
        }
    };

    // Filter requests based on selected priority and status
    const filteredRequests = requests.filter((request) => {
        return (
            (selectedRequestsPriority === 'Show All' || request.priority === selectedRequestsPriority) &&
            (selectedRequestsStatus === 'Show All' ||
                (selectedRequestsStatus === 'Hide Done' && request.status !== 'Done') ||
                request.status === selectedRequestsStatus)
        );
    });

    const handleNewRequestClick = () => {
        const isSelfPlan = currentUser?.Plan === 'SELF';
        const isExternalUser = currentUser?.ExternalPaid === true;

        // Feature is locked (SELF plan can't access Tasks)
        if (isSelfPlan) {
            if (isExternalUser) {
                // Inform external user to contact admin
                Swal.fire({
                    icon: 'info',
                    title: 'Feature Unavailable in Current Plan',
                    html: `The Requests feature is not available in the current subscription plan.<br><br>
                               If you would like to use this feature, please contact your subscription admin:<br><br>
                               <strong>${currentUser?.ExternalPaidAdminName || 'Your Admin'}</strong><br>
                               <a href="mailto:${currentUser?.ExternalPaidAdminEmail}" class="text-primary">${currentUser?.ExternalPaidAdminEmail || ''}</a>`,
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-light-primary'
                    },
                    buttonsStyling: false
                });
            } else {
                // Self plan owner – show upgrade CTA
                Swal.fire({
                    icon: 'info',
                    title: 'Work Better Together',
                    text: 'Requests help you collaborate with clients, paralegals, and contributors by centralizing document requests and follow-ups. Upgrade to the Expert Plan to start working as a team.',
                    confirmButtonText: 'Upgrade Now',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-light'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        openSubscriptionModal('upgrade');
                    }
                });
            }
        } else {
            handleShowRequestModal();
        }
    };

    // Add a new request
    const handleSubmitRequestModal = async (event) => {
        event.preventDefault();

        // Process hashtags to extract only the plain text
        const processedHashtags = hashtags.map(tag => tag.id || tag.text); // Extract only the text or id

        const newRequest = {
            subject: request.subject,
            content: request.content,
            priority: request.priority,
            status: request.status,
            assigneeUserId: request.assigneeUserId,         // Include assignee in the request
            requestType: request.requestType,   // Include requestType in the request
            duedate: request.duedate,         // Include duedate in the request
            hashtags: processedHashtags
        };

        try {
            setLoading(true);

            const createdRequest = await createRequest(petitionId, newRequest, type);
            setRequests((prevRequests) => [...prevRequests, createdRequest]);

            // Reset request state after successful submission
            setRequest({
                subject: '',
                content: '',
                priority: 'Low',
                status: 'Pending',
                assigneeUserId: '',               // Reset assignee
                requestType: 'document request', // Reset request type
                duedate: '',                // Reset duedate
            });

            setHashtags([]);

            handleCloseRequestModal();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Failed to create request:', error);
        }
    };

    // Change request status
    const handleRequestStatusChange = async (id, newStatus) => {
        try {
            setLoading(true);
            await changeRequestStatus(petitionId, id, newStatus, type);
            setRequests((prevRequests) =>
                prevRequests.map((request) =>
                    request.id === id ? { ...request, status: newStatus } : request
                )
            );
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Failed to update request status:', error);
        }
    };

    // Delete request
    const handleDeleteRequest = async (id) => {
        try {
            setLoading(true);
            await deleteRequest(petitionId, id, type);
            await loadRequests(); // Re-fetch requests to ensure correct sequence numbers
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Failed to delete request:', error);
        }
    };

    return (
        <>
            <Loading isLoading={loading} />

            {/* <!--begin::Requests Card--> */}
            <div className="card card-flush h-xl-100">
                {/* <!--begin::Card header--> */}
                <div className="card-header pt-7">
                    {/* <!--begin::Title--> */}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fs-3 fw-bold text-primary">Requests</span>
                        <span className="text-gray-500 mt-1 fw-semibold fs-6">Manage key documents and actions to keep your petition on track</span>
                    </h3>
                    {/* <!--end::Title--> */}
                    {/* Request Filters and Add Request Button */}
                    <div className="card-toolbar">
                        <div className="d-flex flex-stack flex-wrap gap-4">
                            <div className="d-flex align-items-center fw-bold">
                                <div className="text-muted fs-7 me-2">Priority</div>
                                <select
                                    className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                    value={selectedRequestsPriority}
                                    onChange={(e) => handleRequestsFilterChange(e, 'priority')}
                                >
                                    <option value="Show All">Show All</option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </select>
                            </div>
                            <div className="d-flex align-items-center fw-bold">
                                <div className="text-muted fs-7 me-2">Status</div>
                                <select
                                    className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                    value={selectedRequestsStatus}
                                    onChange={(e) => handleRequestsFilterChange(e, 'status')}
                                >
                                    <option value="Show All">Show All</option>
                                    <option value="Pending">Pending</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Done">Done</option>
                                    <option value="Hide Done">Hide Done</option>
                                </select>
                            </div>
                            <Button variant="primary" size="sm" onClick={handleNewRequestClick}>
                                Add Request
                            </Button>
                        </div>
                    </div>
                </div>
                {/* <!--end::Card header--> */}

                {/* <!--begin::Card body--> */}
                {/* Request List with Drag and Drop */}
                <div className="card-body">
                    {(filteredRequests.length > 0 ?
                        <table className="table table-row-dashed align-middle gs-0 gy-6 my-0">
                            <thead>
                                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                    <th className="min-w-150px">Subject</th>
                                    <th className="d-none d-lg-table-cell">Due Date</th>
                                    {/* <th className="d-none d-lg-table-cell">Author</th> */}
                                    <th className="d-none d-lg-table-cell">Assignee</th>
                                    <th className="text-start pe-3 d-none d-lg-table-cell">Type</th>
                                    <th className="text-start pe-3 d-none d-lg-table-cell">Priority</th>
                                    <th className="text-start pe-3 ">Status</th>
                                    <th className="text-center pe-0 min-w-75px">Action</th>
                                </tr>
                            </thead>
                            <tbody className="fw-bold text-gray-600">
                                {filteredRequests.map((request, index) => (
                                    <tr key={index}>
                                        <td>

                                            <div className="gap-1 pt-2">
                                                {/* <!--begin::Heading--> */}
                                                <Link to={`/petition/${petitionId}/${type === "RFEResponse" ? "rfe/" : ""}request/${request.id}`} className="ellipsis">
                                                    <span className="fw-bold fs-6">{request.subject}</span>
                                                </Link>
                                                {/* <!--end::Heading--> */}
                                            </div>
                                            {/* <!--begin::Hashtags--> */}
                                            {request.hashtags?.map((hashtag) => (
                                                <div key={hashtag} className="badge badge-light-primary me-1">{hashtag}</div>
                                            ))}
                                            {/* <!--end::Hashtags--> */}
                                        </td>
                                        <td className={`d-none d-lg-table-cell ${isOverdue(request.duedate) ? 'text-danger' : ''}`}>
                                            {formatDate(request.duedate, true) || "-"}
                                        </td>

                                        <td className="d-none d-lg-table-cell">
                                            <span className="text-gray-800 fw-bold fs-6">
                                                <div className="d-flex align-items-center">
                                                    {/* <!--begin::Avatar--> */}
                                                    <div className="symbol symbol-35px">
                                                        {request.assignee.photo ? (
                                                            <img
                                                                src={request.assignee.photo}
                                                                alt={request.assignee.name}
                                                                className="user-avatar"
                                                            />
                                                        ) : (
                                                            <div className="fallback-avatar" style={{ backgroundColor: generateColorFromName(request.assignee.name) }}>
                                                                {request.assignee.name.charAt(0).toUpperCase()}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <!--end::Avatar--> */}
                                                    {/* <!--begin::Details--> */}
                                                    <div className="ms-2">
                                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">{request.assignee.name}</a>
                                                        {/* <div className="fw-semibold text-muted">{request.assignee.email}</div> */}
                                                    </div>
                                                    {/* <!--end::Details--> */}
                                                </div>
                                            </span>
                                        </td>
                                        <td className="text-start d-none d-lg-table-cell">
                                            <span className={`badge py-3 px-4 fs-7 badge-light-info`}>
                                                {request.requestType}
                                            </span>
                                        </td>
                                        <td className="text-start d-none d-lg-table-cell">
                                            <span className={`badge py-3 px-4 fs-7 badge-light-${request.priority === 'High' ? 'danger' : request.priority === 'Medium' ? 'warning' : 'success'}`}>
                                                {request.priority}
                                            </span>
                                        </td>
                                        <td className="text-start">
                                            <span className={`badge py-3 px-4 fs-7 badge-light-${request.status === 'Done' ? 'success' : request.status === 'In Progress' ? 'primary' : 'danger'}`}>
                                                {request.status}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic"></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {request.status !== 'Pending' && (
                                                        <Dropdown.Item onClick={() => handleRequestStatusChange(request.id, 'Pending')}>
                                                            Mark as Pending
                                                        </Dropdown.Item>
                                                    )}
                                                    {request.status !== 'In Progress' && (
                                                        <Dropdown.Item onClick={() => handleRequestStatusChange(request.id, 'In Progress')}>
                                                            Mark as In Progress
                                                        </Dropdown.Item>
                                                    )}
                                                    {request.status !== 'Done' && (
                                                        <Dropdown.Item onClick={() => handleRequestStatusChange(request.id, 'Done')}>
                                                            Mark as Done
                                                        </Dropdown.Item>
                                                    )}
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={() => handleDeleteRequest(request.id)} className="text-danger">
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        :
                        <div style={{ display: 'grid', placeItems: 'center', height: '60' }}>
                            <img height="300px" src="/assets/media/illustrations/21.png" alt="No files found"></img>
                        </div>
                    )}
                </div>
            </div>
            {/* <!--end::Requests Card--> */}

            {/* Add Request Modal */}
            <Modal show={showRequestModal} onHide={handleCloseRequestModal}>
                <form onSubmit={handleSubmitRequestModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3 fv-row">
                            <Form.Label className="form-label mb-3 required">Subject</Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                name="subject"
                                placeholder="Enter request subject"
                                value={request.subject}
                                onChange={handleChangeRequestModal}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 fv-row">
                            <Form.Label className="form-label mb-3">Content (Optional)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                className="form-control form-control-lg form-control-solid"
                                name="content"
                                placeholder="Enter a content"
                                value={request.content}
                                onChange={handleChangeRequestModal}
                            />
                        </Form.Group>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group className="fv-row">
                                    <Form.Label className="form-label mb-3 required">Priority</Form.Label>
                                    <Form.Select
                                        className="form-control form-control-lg form-control-solid"
                                        name="priority"
                                        value={request.priority}
                                        onChange={handleChangeRequestModal}
                                        required
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="fv-row">
                                    <Form.Label className="form-label mb-3 required">Status</Form.Label>
                                    <Form.Select
                                        className="form-control form-control-lg form-control-solid"
                                        name="status"
                                        value={request.status}
                                        onChange={handleChangeRequestModal}
                                        required
                                    >
                                        <option value="Pending">Pending</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Done">Done</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Assignee Field */}
                        <Form.Group className="mb-3 fv-row">
                            <Form.Label className="form-label mb-3 required">Assignee</Form.Label>
                            <Select
                                name="assigneeUserId"
                                value={sharedWithUsers.find(user => user.id === request.assigneeUserId)}
                                onChange={selectedOption => setRequest({ ...request, assigneeUserId: selectedOption.id })}
                                options={sharedWithUsers}
                                getOptionLabel={user => (

                                    <div className="d-flex align-items-center">
                                        {/* <!--begin::Avatar--> */}
                                        <div className="symbol symbol-35px">
                                            {user.photo ? (
                                                <img
                                                    src={user.photo}
                                                    alt={user.name}
                                                    className="user-avatar"
                                                />
                                            ) : (
                                                <div className="fallback-avatar" style={{ backgroundColor: generateColorFromName(user.name) }}>
                                                    {user.name.charAt(0).toUpperCase()}
                                                </div>
                                            )}
                                        </div>
                                        {/* <!--end::Avatar--> */}
                                        {/* <!--begin::Details--> */}
                                        <div className="ms-2">
                                            <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">{user.name}</a>
                                            <div className="fw-semibold text-muted">{user.email}</div>
                                        </div>
                                        {/* <!--end::Details--> */}
                                    </div>
                                )}
                                getOptionValue={user => user.id}
                                classNamePrefix="react-select"
                                styles={customStyles}  // Apply custom styles here
                            />
                        </Form.Group>

                        {/* Request Type Field */}
                        <Form.Group className="mb-3 fv-row">
                            <Form.Label className="form-label mb-3 required">Request Type</Form.Label>
                            <Form.Select
                                className="form-control form-control-lg form-control-solid"
                                name="requestType"
                                value={request.requestType}
                                onChange={handleChangeRequestModal}
                                required
                            >
                                <option value="document request">Document Request</option>
                                <option value="signature">Signature</option>
                                <option value="clarification">Clarification</option>
                                <option value="follow-up">Follow-up</option>
                                {/* Add more types as needed */}
                            </Form.Select>
                        </Form.Group>

                        {/* Due Date Field */}
                        <Form.Group className="mb-3 fv-row">
                            <Form.Label className="form-label mb-3">Due Date</Form.Label>
                            <Form.Control
                                type="date"
                                className="form-control form-control-lg form-control-solid"
                                name="duedate"
                                value={request.duedate}
                                onChange={handleChangeRequestModal}
                            />
                        </Form.Group>

                        {/* Hashtags Field */}
                        <Form.Group controlId="formHashtags" className="mt-3">
                            <Form.Label>Hashtags</Form.Label>
                            <ReactTagInput
                                tags={hashtags}
                                handleDelete={handleDeleteHashtag}
                                handleAddition={handleAddHashtag}
                                placeholder="Add hashtags"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-light-secondary" onClick={handleCloseRequestModal}>
                            Cancel
                        </Button>
                        <Button className="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default RequestManager;
