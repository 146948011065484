import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import Loading from '../../components/Loading/Loading.js';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { fetchForms, fetchExhibits, fetchExhibitFiles, fetchCoverLetter, fetchPlan } from '../../services/ApiService.js';
import { createPrintVersion } from '../../services/AIApiService.js';
import { getFormBadgeClass } from '../../utils/Utils.js';
import { useSubscription } from '../../contexts/SubscriptionContext';

function PetitionMyPetition({ petitionId, hasCoverLetter, hasPlan, petitionFolderId, type, petitionerName }) {
    const { currentUser } = useAuth();
    const { logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [coverLetter, setCoverLetter] = useState(null);
    const [plan, setPlan] = useState(null);
    const [forms, setForms] = useState([]);
    const [exhibits, setExhibits] = useState([]);
    const [files, setFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(true);
    const navigate = useNavigate();
    const { openSubscriptionModal } = useSubscription();

    useEffect(() => {
        async function fetchData() {
            await fetchFormsCall(petitionId, setForms);
            fetchExhibitsCall(petitionId);

            // if (hasCoverLetter) {
            fetchCoverLetterCall(petitionId, setCoverLetter);
            // }
            // if (hasPlan) {
            fetchPlanCall(petitionId, setPlan);
            // }
        }

        fetchData();

    }, [petitionId]);

    async function fetchCoverLetterCall(petitionId, setCoverLetter) {
        fetchCoverLetter(petitionId, type)
            .then((result) => {
                setCoverLetter(result);
            })
            .catch((error) => {
                console.error('Error fetching cover letter:', error);
                return null;
            });
    }


    async function fetchPlanCall(petitionId, setPlan) {
        fetchPlan(petitionId, type)
            .then((result) => {
                setPlan(result);
            })
            .catch((error) => {
                console.error('Error fetching plan:', error);
                return null;
            });
    }

    async function fetchFormsCall(petitionId, setForms) {
        const formsList = await fetchForms(petitionId, type);

        setForms(formsList);
    }

    async function fetchExhibitsCall(petitionId) {
        const exhibitsList = await fetchExhibits(petitionId, type);

        const allFiles = await Promise.all(exhibitsList.map(async (exhibit) => {
            try {
                const files = await fetchExhibitFiles(petitionId, exhibit.id, type);

                const updatedFiles = await Promise.all(files.map(async (file) => {

                    // Add exhibit sequenceNumber to the file object
                    file.sequenceNumber = exhibit.sequenceNumber;
                    file.exhibitId = exhibit.id;
                    // console.log(file)
                    return file;
                }));

                return updatedFiles;
            } catch (error) {
                console.error('Error fetching files for exhibit:', exhibit.id, error);
                return [];
            }
        }));

        setLoadingFiles(false);
        setFiles(allFiles.flat()); // Flatten the array of arrays into a single array of files
    }

    function getStatusColor(input) {
        const statuses = ["primary", "danger", "info", "success", "warning"];
        const index = Math.abs(input) % statuses.length;
        return statuses[index];
    }

    const handleMergePdfs = async () => {
        setLoading(true);

        try {
            const response = await createPrintVersion(petitionId, type);

            setLoading(false);

            if (response.fileUrl.length === 0) {
                Swal.fire("Error generating PDF file.", "Please, try again removing some Forms to see if it works!", "error");
            } else {
                let problematicDetails = '';

                if (response.problematic_forms.length > 0) {
                    problematicDetails += `<br /><br />Problematic Form(s):<br />${response.problematic_forms.join('<br />')}`;
                }
                if (response.problematic_files.length > 0) {
                    problematicDetails += `<br /><br />Problematic File(s):<br />${response.problematic_files.join('<br />')}`;
                }
                if (response.problematic_exhibits.length > 0) {
                    problematicDetails += `<br /><br />Problematic Exhibit(s):<br />${response.problematic_exhibits.join('<br />')}`;
                }

                if (response.problematic_forms.length === 0 &&
                    response.problematic_files.length === 0 &&
                    response.problematic_exhibits.length === 0) {

                    Swal.fire("Petition PDF generated successfully!", `<a target='_blank' href='${response.fileUrl}'>${response.fileUrl}</a>`, "success");
                } else if (response.problematic_forms.length > 0 &&
                    response.problematic_files.length === 0 &&
                    response.problematic_exhibits.length === 0) {

                    Swal.fire("Petition PDF generated successfully, but some Forms were skipped.", `<a target='_blank' href='${response.fileUrl}'>${response.fileUrl}</a>.${problematicDetails}`, "info");
                } else {
                    Swal.fire("Error", `Some exhibits presented issues compromising the whole printing. To avoid any mistakes in your petition printing we advise you to print the exhibits outside Visa Genius.${problematicDetails}`, "error");
                }
            }
        } catch (error) {
            setLoading(false);
            Swal.fire("Error", `Error: ${error}`, "error");
        }
    };

    const handleMergePdfsClick = () => {
        const isSelfPlan = currentUser?.Plan === 'SELF';

        if (isSelfPlan) {
            Swal.fire({
                icon: 'info',
                title: 'Save Time with One-Click Printing',
                text: 'Compile your entire petition — cover letter, exhibits, and plan — into a professional, paginated PDF with automatic Table of Contents. Avoid operational mistakes and focus on what matters.',
                confirmButtonText: 'Upgrade Now',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-light'
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.isConfirmed) {
                    openSubscriptionModal('upgrade');
                }
            });
        } else {
            handleMergePdfs();
        }
    };


    return (
        <>
            <Loading isLoading={loading} />
            <div className="card">
                <div className="card-header">
                    {/* <!--begin::Card title--> */}
                    <div className="card-title">
                        <h3 className='card-title align-items-start flex-column'>
                            <span className="card-label fs-2 fw-bold text-primary">{petitionerName}'s Petition</span>
                            <span className="text-gray-500 mt-1 fw-semibold fs-6">Review the whole petition with ease.</span>
                        </h3>
                    </div>
                    {/* <!--end::Card title--> */}
                    {/* <!--begin::Card toolbar--> */}
                    <div className="card-toolbar">
                        {/* <!--begin::Button--> */}
                        {/* <span className="btn btn-sm btn-primary" onClick={handleMergePdfs}><i className="ki-outline ki-cloud-download fs-2 text-white"></i> PDF</span> */}
                        <div className="card-toolbar">
                            <button
                                className="btn btn-sm btn-primary"
                                onClick={handleMergePdfsClick}
                                disabled={loading}>
                                {loading ? (
                                    <span className="spinner-border spinner-border-sm"></span>
                                ) : (
                                    <i className="ki-outline ki-cloud-download fs-2 text-white"></i>
                                )}
                                PDF
                            </button>
                        </div>

                        {/* // 3. Add style for the lock icon (in your CSS):
                        .ki-lock:before {
                            content: "\\";
                        font-family: "Keenicons";
                        font-size: 1.2rem;
} */}
                        {/* <!--end::Button--> */}
                    </div>
                    {/* <!--end::Card toolbar--> */}
                </div>

                <div className="card-body">
                    {/* <!--begin::Label--> */}
                    <div className="fs-3 fw-bold mb-3">Forms</div>
                    {/* <!--end::Label--> */}

                    <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                        {forms.map(form => (
                            <div key={form.id} className="col-sm-6 col-xxl-2">
                                {/* <!--begin::Overlay--> */}
                                <a className="d-block overlay"
                                    data-fslightbox="lightbox-hot-sales"
                                    href={form.isFirestore
                                        ? form.storageUrl // If the file is stored in Firebase Storage, use the storage URL
                                        : `https://drive.google.com/file/d/${form.googleDocId}/view`}  // Otherwise, fall back to Google Drive URL
                                    target="_blank"
                                    rel="noopener noreferrer">

                                    {/* <!--begin::Image--> */}
                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover mb-2 border border-gray-600"
                                        style={{
                                            height: "240px", backgroundImage: `url("${form.isDocs
                                                ? "/assets/images/GoogleDriveLogo.png"  // Show Google Docs logo for Docs files
                                                : form.isFirestore
                                                    ? form.thumbnailLink  // Use the Firebase Storage thumbnail for Firestore files
                                                    : form.thumbnailLink}")`  // Otherwise, use the Drive thumbnail
                                        }}>
                                    </div>
                                    {/* <!--end::Image--> */}

                                    {/* <!--begin::Action--> */}
                                    <div className="overlay-layer bg-dark bg-opacity-25">
                                        <i className="ki-outline ki-eye fs-3x text-white"></i>
                                    </div>
                                    {/* <!--end::Action--> */}
                                </a>

                                {/* <!--end::Overlay--> */}
                                {/* <!--begin::Info--> */}
                                <span className={`text-start badge badge-light-primary`}>{getFormBadgeClass(form.formType)}</span>
                                <div className="d-flex align-items-end flex-stack mb-1 mt-1">
                                </div>
                                {/* <!--end::Info--> */}
                                {/* <!--end::Overlay--> */}
                            </div>
                        ))}
                        {forms.length == 0 && (<div>No forms found.</div>)}

                    </div>

                    {/* <!--begin::Label--> */}
                    <div className="fs-3 fw-bold mb-3">Cover Letter and Plan</div>
                    {/* <!--end::Label--> */}

                    <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                        {coverLetter?.googleDocId ? (
                            <div className="col-sm-6 col-xxl-2">
                                {/* <!--begin::Overlay--> */}
                                <a className="d-block overlay" data-fslightbox="lightbox-hot-sales"
                                    href={coverLetter?.isDocs
                                        ? `https://docs.google.com/document/d/${coverLetter?.googleDocId}/edit`
                                        : `https://drive.google.com/file/d/${coverLetter?.googleDocId}/view`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {/* <!--begin::Image--> */}
                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover mb-2 border border-gray-600" style={{ height: "240px", backgroundImage: `url("${coverLetter?.isDocs != "0" ? "/assets/images/GoogleDriveLogo_old.png" : coverLetter?.thumbnailLink}")` }}></div>
                                    {/* <!--end::Image--> */}
                                    {/* <!--begin::Action--> */}
                                    <div className="overlay-layer bg-dark bg-opacity-25">
                                        <i className="ki-outline ki-eye fs-3x text-white"></i>
                                    </div>
                                    {/* <!--end::Action--> */}
                                </a>
                                {/* <!--end::Overlay--> */}
                                {/* <!--begin::Info--> */}
                                <span className="text-start fw-bold text-gray-700 cursor-pointer text-hover-primary fs-5 d-block">Cover Letter</span>
                                <div className="d-flex align-items-end flex-stack mb-1 mt-1"></div>
                                {/* <!--end::Info--> */}
                            </div>
                        ) :
                            <div>No cover letter found.</div>
                        }

                        {plan?.googleDocId ? (
                            <div className="col-sm-6 col-xxl-2">
                                {/* <!--begin::Overlay--> */}
                                <a className="d-block overlay" data-fslightbox="lightbox-hot-sales"
                                    href={plan?.isDocs
                                        ? `https://docs.google.com/document/d/${plan?.googleDocId}/edit`
                                        : `https://drive.google.com/file/d/${plan?.googleDocId}/view`}

                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {/* <!--begin::Image--> */}
                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover mb-2 border border-gray-600" style={{ height: "240px", backgroundImage: `url("${plan?.isDocs != "0" ? "/assets/images/GoogleDriveLogo_old.png" : plan?.thumbnailLink}")` }}></div>
                                    {/* <!--end::Image--> */}
                                    {/* <!--begin::Action--> */}
                                    <div className="overlay-layer bg-dark bg-opacity-25">
                                        <i className="ki-outline ki-eye fs-3x text-white"></i>
                                    </div>
                                    {/* <!--end::Action--> */}
                                </a>
                                {/* <!--end::Overlay--> */}
                                {/* <!--begin::Info--> */}
                                <span className="text-start fw-bold text-gray-700 cursor-pointer text-hover-primary fs-5 d-block">Plan</span>
                                <div className="d-flex align-items-end flex-stack mb-1 mt-1"></div>
                                {/* <!--end::Info--> */}
                            </div>
                        ) :
                            <div>No plan found.</div>
                        }

                    </div>

                    {/* <!--begin::Label--> */}
                    <div className="fs-3 fw-bold mb-3">Other Exhibits</div>
                    {/* <!--end::Label--> */}

                    <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                        {loadingFiles && (<div>Loading exhibits...</div>)}
                        {files.map(file => (
                            <div key={file.id} className="col-sm-6 col-xxl-2 mb-3">
                                {/* <!--begin::Overlay--> */}
                                <a className={`d-block overlay ${file.isHidden ? 'hidden-mask' : ''}`}
                                    data-fslightbox="lightbox-hot-sales"
                                    href={file.isDocs
                                        ? `https://docs.google.com/document/d/${file.googleDocId}/edit`
                                        : file.isFirestore
                                            ? file.storageUrl  // Use Firebase Storage URL if `isFirestore` is true
                                            : `https://drive.google.com/file/d/${file.googleDocId}/view`}  // Fall back to Google Drive URL
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-bs-toggle="tooltip"
                                    title={`${file.isHidden ? 'Hidden files will not be included in the print version.' : ''}`}>

                                    {/* <!--begin::Image--> */}
                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover mb-2 border border-gray-600"
                                        style={{
                                            height: "266px", backgroundImage: `url("${file.isDocs
                                                ? "/assets/images/GoogleDriveLogo_old.png"  // Show Google Docs logo for Docs files
                                                : file.isFirestore
                                                    ? file.thumbnailLink  // Use Firebase Storage thumbnail if `isFirestore` is true
                                                    : file.thumbnailLink}")`  // Fall back to the Drive thumbnail
                                        }}>
                                    </div>
                                    {/* <!--end::Image--> */}

                                    {/* <!--begin::Action--> */}
                                    <div className="overlay-layer bg-dark bg-opacity-25">
                                        <i className="ki-outline ki-eye fs-3x text-white"></i>
                                    </div>
                                    {/* <!--end::Action--> */}
                                </a>

                                {/* <!--end::Overlay--> */}
                                {/* <!--begin::Info--> */}
                                <Link to={`/petition/${petitionId}/${type === "RFEResponse" ? "rfe/" : ""}exhibit/${file.exhibitId}`}>
                                    <span className={`text-start badge badge-light-${getStatusColor(file.sequenceNumber)}`}>Exhibit {file.sequenceNumber}</span>
                                </Link>
                                {/* <span className="text-start fw-bold text-gray-700 cursor-pointer text-hover-primary fs-5 d-block">{file.fileName} {file.sequenceNumber}</span> */}
                                {/* <div div className="d-flex align-items-end flex-stack mb-1 mt-1" ></div> */}
                                {/* <!--end::Info--> */}
                            </div>
                        ))}
                        {!loadingFiles && files.length == 0 && (<div>No exhibits found.</div>)}
                    </div>
                </div >
            </div >
        </>
    );
}

export default PetitionMyPetition;
