import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { useAuth } from '../../contexts/AuthContext.js';
import { generateColorFromName } from '../../utils/Utils.js'
import { checkUserByEmail, addUserToSubscription, getPaidUsers, getReadOnlyUsers, removeUserFromSubscription } from '../../services/ApiService';

const UserManagementPanel = ({ handleAddSeat }) => {
    const { currentUser } = useAuth();
    const { openSubscriptionModal } = useSubscription();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [userType, setUserType] = useState('paid'); // 'paid' or 'read-only'
    const [paidUsers, setPaidUsers] = useState([]);
    const [readOnlyUsers, setReadOnlyUsers] = useState([]);

    const [emailInput, setEmailInput] = useState('');
    const [existingUser, setExistingUser] = useState(null);
    const [inviteRequired, setInviteRequired] = useState(false);

    const handleAddUserSubmit = (e) => {
        e.preventDefault();
        setNewUserEmail('');
        setUserType('paid');
        setShowAddUserModal(false);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const paid = await getPaidUsers();
            const readonly = await getReadOnlyUsers();
            setPaidUsers(paid);
            setReadOnlyUsers(readonly);
        } catch (err) {
            console.error('Error loading users:', err);
        }
    };

    const handleAddUser = async () => {
        if (!emailInput) return;

        try {
            const result = await checkUserByEmail(emailInput);

            if (result.exists) {
                await addUserToSubscription(emailInput, userType);

                const resultDialog = await Swal.fire({
                    icon: 'success',
                    title: 'User added!',
                    text: `${result.name} has been added as a ${userType === 'paid' ? 'paid user' : 'read-only user'}.`,
                    customClass: {
                        confirmButton: 'btn btn-success'
                    },
                    buttonsStyling: false
                });

                setEmailInput('');
                setExistingUser(null);
                setInviteRequired(false);
                // fetchUsers(); // This should refresh both paid and readonly lists

                if (resultDialog.isConfirmed) {
                    window.location.reload();
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'User not found',
                    text: 'Only registered users can be added. Ask them to sign up before you try again.',
                    customClass: {
                        confirmButton: 'btn btn-warning'
                    },
                    buttonsStyling: false
                });
            }
        } catch (err) {
            console.error(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: 'Error checking or adding user.',
                customClass: {
                    confirmButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });
        }
    };
    const handleRemoveUser = async (userId, type) => {
        const confirmation = await Swal.fire({
            title: 'Confirm Deletion',
            text: `Are you sure you want to remove this ${type === 'paid' ? 'team member' : 'viewer'}? They will no longer have access to any shared petitions.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-light'
            },
            buttonsStyling: false
        });

        if (confirmation.isConfirmed) {
            try {
                await removeUserFromSubscription({ userIdToRemove: userId, userType: type });
                const confirmResult = await Swal.fire('Removed!', 'The user has been removed.', 'success');

                if (confirmResult.isConfirmed) {
                    window.location.reload();
                }

            } catch (err) {
                console.error(err);
                Swal.fire('Error', 'Something went wrong while removing the user.', 'error');
            }
        }
    };

    const handleAddViewerClick = () => {
        const isSelfPlan = currentUser?.Plan === 'SELF';
        const isExternalUser = currentUser?.ExternalPaid === true;

        if (isSelfPlan) {
            Swal.fire({
                icon: 'info',
                title: 'Feature Only Available in Expert Plan',
                text: 'The ability to add Viewers (read-only free users) is available only in the Expert Plan. Upgrade to manage your team with more flexibility.',
                confirmButtonText: 'Upgrade Now',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-light'
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.isConfirmed) {
                    openSubscriptionModal('upgrade');
                }
            });
        } else {
            setUserType('readonly');
            setShowAddUserModal(true);
        }
    };

    return (
        <div className="row">
            {/* Paid Users Card */}
            <div className="col-lg-6">
                <div className="card p-5 mb-10">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="fw-bold mb-0">Team Members (Write Access)</h4>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                                const totalSeats = currentUser?.Seats || 0;
                                const currentPaidUsersCount = paidUsers.length + 1; // +1 for the admin user
                                const remainingSeats = totalSeats - currentPaidUsersCount;

                                if (remainingSeats > 0) {
                                    setUserType('paid');
                                    setShowAddUserModal(true);
                                } else {
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'No Available Seats',
                                        text: 'You’ve reached the maximum number of Team Members allowed by your plan. To add more users, please purchase additional seats.',
                                        confirmButtonText: 'Buy More Seats',
                                        showCancelButton: true,
                                        customClass: {
                                            confirmButton: 'btn btn-primary',
                                            cancelButton: 'btn btn-light'
                                        },
                                        buttonsStyling: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            handleAddSeat();
                                        }
                                    });
                                }
                            }}
                        >
                            Add Team Member
                        </Button>

                    </div>

                    {Array.isArray(paidUsers) && paidUsers.length === 0 && (
                        <div className="">No team members yet.</div>
                    )}

                    {Array.isArray(paidUsers) && paidUsers.length > 0 && (
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {paidUsers.map((user) => (
                                    <tr key={user.id}>
                                        <td>
                                            <div className="symbol symbol-35px">
                                                {user.photo ? (
                                                    <img src={user.photo} alt={user.name} className="user-avatar rounded-circle" />
                                                ) : (
                                                    <div className="fallback-avatar badge badge-light" style={{ backgroundColor: generateColorFromName(user.name) }}>
                                                        {user.name?.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td className="text-end">
                                            <button
                                                className="btn btn-icon btn-sm btn-light-danger"
                                                onClick={() => handleRemoveUser(user.id, 'paid')}
                                                title="Remove Paid User"
                                            >
                                                <i className="ki-outline ki-trash fs-2"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                </div>
            </div>

            {/* Read-Only Users Card */}
            <div className="col-lg-6">
                <div className="card p-5 mb-10">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="fw-bold mb-0">Viewers (Read-only Free Access)</h4>
                        <Button variant="primary" size="sm" onClick={handleAddViewerClick}>
                            Add Viewer
                        </Button>
                    </div>

                    {Array.isArray(readOnlyUsers) && readOnlyUsers.length === 0 && (
                        <div className="">No viewers yet.</div>
                    )}

                    {Array.isArray(readOnlyUsers) && readOnlyUsers.length > 0 && (
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {readOnlyUsers.map((user) => (
                                    <tr key={user.id}>
                                        <td>
                                            <div className="symbol symbol-35px">
                                                {user.photo ? (
                                                    <img src={user.photo} alt={user.name} className="user-avatar rounded-circle" />
                                                ) : (
                                                    <div className="fallback-avatar badge badge-light" style={{ backgroundColor: generateColorFromName(user.name) }}>
                                                        {user.name?.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td className="text-end">
                                            <button
                                                className="btn btn-icon btn-sm btn-light-danger"
                                                onClick={() => handleRemoveUser(user.id, 'readonly')}
                                                title="Remove Read Only User"
                                            >
                                                <i className="ki-outline ki-trash fs-2"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                </div>
            </div>

            {/* Modal */}
            <Modal show={showAddUserModal} onHide={() => setShowAddUserModal(false)}>
                <form onSubmit={handleAddUserSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{userType === 'paid' ? 'Add Team Member' : 'Add Viewer'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label className="form-label required">User Email</label>
                            <input
                                type="email"
                                className="form-control"
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-light-secondary' onClick={() => setShowAddUserModal(false)}>Cancel</Button>
                        <Button type="submit" variant="primary" onClick={handleAddUser}>Add User</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};

export default UserManagementPanel;
