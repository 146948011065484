import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // Added loading state
    const [loginInProgress, setLoginInProgress] = useState(false); // Track login process
    const db = getFirestore();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser && !loginInProgress) { // Only fetch user if not in login process
                const userRef = doc(db, "Users", firebaseUser.uid);
                const docSnap = await getDoc(userRef);

                const userForApp = {
                    Name: firebaseUser.displayName,
                    Email: firebaseUser.email,
                    Photo: firebaseUser.photoURL,
                    ProviderId: firebaseUser.providerData[0].providerId,
                    uid: firebaseUser.uid,
                    BaseFolderId: docSnap.exists() ? docSnap.data().BaseFolderId : null,
                    ProfileType: docSnap.exists() ? docSnap.data().profileType : null,
                    Plan: docSnap.exists() ? (docSnap.data().plan?.toUpperCase() ?? "FREE") : "FREE",
                    Seats: docSnap.exists() ? docSnap.data().seats : null,
                    SubscriptionStatus: docSnap.exists() ? (docSnap.data().subscriptionStatus ?? "inactive") : "inactive",
                    createdAt: docSnap.exists() ? docSnap.data().createdAt?.toDate?.() : null,
                    StartTrialDate: docSnap.exists() ? docSnap.data().startTrialDate?.toDate?.() : null,
                    EndTrialDate: docSnap.exists() ? docSnap.data().endTrialDate?.toDate?.() : null,
                    SubscriptionEndDate: docSnap.exists() ? docSnap.data().subscriptionEndDate?.toDate?.() : null,
                    SubscriptionPriceAmount: docSnap.exists() ? docSnap.data().subscriptionPrice?.amount : 0,
                    SubscriptionPriceCurrency: docSnap.exists() ? docSnap.data().subscriptionPrice?.currency?.toUpperCase?.() : 'USD',
                    ExternalPaid: docSnap.exists() ? docSnap.data().externalPaid : false,
                    ExternalPaidAdminEmail: docSnap.exists() ? docSnap.data().externalPaidAdminEmail : "",
                    ExternalPaidAdminName: docSnap.exists() ? docSnap.data().externalPaidAdminName : ""
                };

                if (userForApp.Plan == "SELF") userForApp.PlanName = "Self Petitioner";
                if (userForApp.Plan == "EXPERT") userForApp.PlanName = "Expert";

                setCurrentUser(userForApp);
            } else {
                setCurrentUser(null);
            }
            setLoading(false); // Set loading to false once auth state is determined
        });

        return () => unsubscribe();
    }, [loginInProgress, db]);

    const logout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    const login = (user) => {
        setCurrentUser(user);
    };

    const startLogin = () => {
        setLoginInProgress(true); // Set login in progress to true
    };

    const endLogin = () => {
        setLoginInProgress(false); // Set login in progress to false
    };

    const value = {
        currentUser,
        setCurrentUser,
        login,
        logout,
        startLogin,
        endLogin,
        loading, // Expose loading state
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
